@import '../../../styles/app';

.terms-and-conditions-cont {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 100;
    left: 0px;
    top: 0px;
    overflow-y: scroll;
    overflow-x: hidden;

    &.tc-black {
        background-color: rgba(0, 0, 0, 0.95);
    }

    .tc-terms-customer-copy > * {
        text-align: left;
    }

    .tc-inner-cont {
        position: absolute;
        width: 90%;
        left: 5%;
        color: #000000;
        display: block;
        border-radius: 10px;
        background-color: white;
        box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.15006);
        z-index: 5;
        padding: 38px;

        .tc-title {
            color: #000000;
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: 1.5rem;
        }

        .tc-terms-toggle {
            cursor: pointer;
            color: #000000;
            font-weight: bold;
            position: relative;
            margin-left: 2.7rem;
            img {
                position: absolute;
                margin-left: -2.7rem;
                margin-top: -0.4rem;
            }
        }

        .tc-button-cont {
            width: 200px;
            margin-left: -5px;
            margin-top: 15px;
            > .mid-button {
                background: #0493CF !important;
                color: white !important;
                border: none !important;
                &:hover {
                    opacity: 0.6;
                }
            }
        }

        .bg-widget-transparent {
            border: none !important;
            background-color: transparent !important;
        }

        .tc-sep {
            display: block;
            width: 100%;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.4);
        }
    }
}