.org-row {
  display: block;
  background: #252D3A;
  border-radius: 5px;
  padding: 8px;
  padding-right: 0px;
  width: 100%;
  margin-right: 30px;
  margin-bottom: 2px;
  vertical-align: top;
  cursor: pointer;
  &:hover, &.selected {
    background: #55B9FC;
    &.selected {
      background: #75E9FF
    }
    .org-cell {
      color: #000;
    }
  }

  .org-cell {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.0004em;
    color: #DDDDDD;
    margin-top: 2px;
    margin-bottom: 4px;
    max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
