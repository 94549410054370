.insight-card {
  display: inline-block;
  background: #252D3A;
  border-radius: 24px;
  padding: 22px;
  padding-right: 0px;
  width: 300px;
  height: 279px;
  margin-right: 30px;
  margin-bottom: 30px;

  .sep-offer {
    border-bottom: 1px solid #252D3A;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .insight-title-cont {
    width: 100%;
    margin-top: 20.8px;
    img {
      display: inline-block;
      height: 40px;
      margin-right: 12.8px;
    }
    .insight-title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.0015em;
      color: #DDDDDD;
      margin-top: -6px;
      margin-bottom: 4px;
      width: 260px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .insight-building-name {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.0025em;
      color: #DDDDDD;
      margin-top: 4px;
      margin-bottom: 4px;
      width: 260px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .insight-view-more + .insight-view-more {
    margin-left: 10px;
  }

  .insight-view-more {
    display: inline-block;
    cursor: pointer;
    background: #3498DB;
    border-radius: 10px;
    padding-left: 14px;
    padding-right: 14px;
    height: 36px;
    width: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 36px;
    letter-spacing: 0.0125em;
    color: #FFFFFF;
    text-align: center;
    margin-top: 18px;
    &:hover {
      background: #54B8FB;
    }
  }

  .insight-status {
    float: right;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.0125em;
    margin-right: 16px;
    padding: 8px;
    border-radius: 86px;
    height: 30px;
    &.published {
      color: #20C2A2;
      //border: 1px solid rgb(32, 194, 162);
    }
    &.draft {
      color: #AAB2BD;
      //border: 1px solid #E7E73C;
    }
    &.rejected {
      color: #FF6C5C;
      //border: 1px solid #FF6C5C;
    }
  }

  .insight-pullout-cont {
    position: fixed;
    top: 0px; left: 0px;
    width: 100%; height: 100%;

    background: #000000D0;
    z-index: 2;

    .insight-pullout {
      position: absolute;
      width: 685px;
      height: 100%;
      left: calc(100% - 597px);
      top: 0px;
      background: #1C222D;
      border: 1px solid #252D3A;
      box-sizing: border-box;
      box-shadow: -8px 4px 19px rgba(9, 12, 18, 0.46);
      border-radius: 20px;
      padding: 22px;

      .insight-type-block {
        margin-top: 26px;
      }

      .insight-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.0025em;
        margin-top: 25px;
        color: #DDDDDD;
      }

      .insight-subtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.0015em;
        color: #DDDDDD;
        margin-top: 6px;
      }

      .insight-name-addr {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        letter-spacing: 0.001em;
        color: #DDDDDD;
        margin-top: 6px;
        margin-bottom: 15px;
      }

      .insight-header-item {
        display: inline-block;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        letter-spacing: 0.001em;
        color: #7A828D;
        span {
          color: #DDDDDD;
        }
        &.left {
          width: 45%;
        }
        &.right {
          width: 40%;
        }
        &.full {
          width: 100%;
        }
        margin-top: 5px;
      }

      .insight-tab-cont {
        width: 550px;
        height: 50px;
        margin-top: 27px;
        border-bottom: 1px solid #252D3A;
        .insight-tab {
          height: 50px;
          display: inline-block;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 35px;
          letter-spacing: 0.001em;
          cursor: pointer;
          color: #DDDDDD;
          border-bottom: 1px solid #252D3A;
          &.tab-selected {
            cursor: initial;
            pointer-events: none;
            color: #3498DB;
            border-bottom: 3px solid #3498DB;
          }
        }
      }
      .insight-tab-content {
        display: block;
        width: 600px;
        border-bottom: 1px solid #252D3A;
        height: calc(100% - 385px);
        overflow-y: scroll;

        .itc-overview {
          margin-top: 12px;
          white-space: pre-line;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.001em;
          color: #DDDDDD;
          width: 545px;
        }

        .insight-tab-att-title {
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
          letter-spacing: 0.0015em;
          color: #FFFFFF;
          margin-top: 30px;
        }

        .insight-tab-att {
          background: #252D3A;
          border-radius: 9px;
          height: 44px;
          margin-top: 15px;
          width: 562px;
          position: relative;
          .itatt-filename {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.001em;
            color: #DDDDDD;
            position: absolute;
            left: 15px;
            top: 13px;
          }
          img {
            float: right;
            cursor: pointer;
            position: absolute;
            right: 15px;
            top: 12px;
          }
        }
      }
      &.offer-pullout {
        .insight-tab-content {
          height: calc(100% - 605px);
        }
        .offer-fields {
          height: 180px;
        }
      }
      .insight-tab-ctas {
        margin-top: 35px;
        .ask-for-quote {
          display: inline-block;
          width: 115px;
          height: 44px;
          background: #55B9FC;
          border-radius: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.0125em;
          color: #FFFFFF;
          text-align: center;
          line-height: 44px;
          cursor: pointer;
        }
        .ask-for-expert {
          display: inline-block;
          width: 175px;
          height: 44px;
          background: #55B9FC;
          border-radius: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.0125em;
          color: #FFFFFF;
          text-align: center;
          line-height: 44px;
          cursor: pointer;
        }
        .ask-for-est {
          display: inline-block;
          width: 145px;
          height: 44px;
          background: #55B9FC;
          border-radius: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.0125em;
          color: #FFFFFF;
          text-align: center;
          line-height: 44px;
          cursor: pointer;
        }
        .start-project {
          display: inline-block;
          width: 135px;
          height: 44px;
          background: #55B9FC;
          border-radius: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.0125em;
          color: #FFFFFF;
          text-align: center;
          line-height: 44px;
          cursor: pointer;
        }
        .contact-vendor {
          margin-left: 20px;
          display: inline-block;
          width: 129px;
          height: 44px;
          border: 1px solid #55B9FC;
          border-radius: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.0125em;
          color: #55B9FC;
          text-align: center;
          line-height: 44px;
          cursor: pointer;
        }
        .contact-building-owner {
          margin-left: 20px;
          display: inline-block;
          width: 189px;
          height: 44px;
          border: 1px solid #55B9FC;
          border-radius: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.0125em;
          color: #55B9FC;
          text-align: center;
          line-height: 44px;
          cursor: pointer;
        }
        .confirm-button-wrapper {
          margin-left: 20px;
        }
      }
    }
  }
}
