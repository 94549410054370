.ac-cont {
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 32px;
  .ac-header {
    cursor: pointer;
    height: 64px;
    color: #DDD;
    .ac-header-text {
      width: 100%;
      font-size: 18px;
      text-align: center;
    }
  }
  .accordion-toggle {
    i {
      font-size: 22px;
      color: #AAA;
    }
  }
  .accordion-checkbox-inline {
    margin-right: 0.5em;
    margin-left: 2em;
    cursor: pointer;
  }
  .ac-rec-error-1 {
    color: #d08010;
  }
  .ac-rec-error-2 {
    color: #e05050;
  }
}

.ac-field {
  width: 50%;
  label {
    color: #d0d0d0;
  }
}

textarea {
  resize: none;
}

.ac-field-list {
  margin-top: -16px;
  margin-bottom: 32px;
}

.mid-button {
  width: 100%;
}