.new-header {
  width: 100%;

  padding-left: 29px;
  padding-right: 29px;

  .logout-btn {
  	position: absolute;
  	right: -64px;
  	top: 14px;
  	width: 96px;
  	cursor: pointer;
  	opacity: 0.8;
  	&:hover {
  		opacity: 1.0;
  	}
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0125em;
    color: #DDDDDD;
    display: inline-block;
    img {
      height: 18px;
      margin-right: 5px;
      margin-top: -2px;
    }
  }

 	.header-bottom {
    height: 58px;
    width: 100%;
    border-bottom: 2px solid #252D3A;
    .goal-completed {
    	line-height: 58px;
    	.gc-label {
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 23px;
				letter-spacing: 0.0015em;
				color: #7A828D;
				margin-right: 7px;
    	}
    	.gc-value {
    		font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 23px;
				letter-spacing: 0.0015em;
				color: #00B894;
				margin-right: 3px;
    	}
    	.gc-unit {
    		font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 23px;
				letter-spacing: 0.0015em;
				color: #DDDDDD;
    	}
    	.gc-view-metrics {
    		float: right;
    		line-height: 58px;
    		height: 58px;
    		font-style: normal;
				font-weight: bold;
				font-size: 14px;
				letter-spacing: 0.0125em;
				text-decoration-line: underline;
				color: #55B9FC;
				cursor: pointer;
				&:hover {
					color: #DDDDDD;
				}
    	}
    }
  }
  .metrics-container {
  	position: relative;
  	width: 1005;
		height: 245px;
		background: #1C222D;
		box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.47);
		margin-bottom: -245px;
		z-index: 1;
		.mc-goals {
			display: inline-block;
			height: 176px;
			background: #252D3A;
			border-radius: 24px;
			width: 30%;
			position: relative;
			top: 35px;
			left: 40px;
			.mc-title {
				top: 15px;
				position: relative;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 23px;
				text-align: center;
				letter-spacing: 0.0015em;
				color: #FFFFFF;
				margin-bottom: 32px;
			}
			.goal-bar {
				display: inline-block;
				background: #1C222D;
				border-radius: 73px;
				margin-left: 18px;
				width: calc(100% - 100px);
				height: 28px;
				.goal-bar-inner {
					position: relative;
					top: 6px;
					left: 8px;
					height: 16px;
					background: #00CEC9;
					opacity: 0.78;
					border-radius: 73px;
				}
			}
			.goal-bar-val {
				display: inline-block;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 23px;
				letter-spacing: 0.0015em;
				color: #00CEC9;
				height: 28px;
				vertical-align: top;
				margin-left: 11px;
			}
			.goal-checks {
				margin-top: 10px;
				margin-left: 18px;
				.goal-check {
					display: inline-block;
					width: 50%;
					margin-bottom: 12px;
					opacity: 0.25;
					&.checked {
						opacity: 1.0;
					}
					img {

					}
					span {
						margin-left: 12px;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						letter-spacing: 0.001em;
						color: #FFFFFF;
					}
				}
			}
		}
		.mc-estar {
			display: inline-block;
			height: 176px;
			background: #252D3A;
			border-radius: 24px;
			width: 20%;
			margin-left: 4%;
			position: relative;
			top: 35px;
			vertical-align: top;
			.mc-title {
				width: 80%;
				top: 15px;
				left: 10%;
				position: relative;
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
				text-align: center;
				letter-spacing: 0.0015em;
				color: #FFFFFF;
				margin-bottom: 32px;
			}
			.mc-estar-val {
				position: relative;
				top: 15px;
				font-style: normal;
				font-weight: normal;
				font-size: 66px;
				line-height: 77px;
				text-align: center;
				letter-spacing: -0.015em;
				color: #74B9FF;
			}
		}
		.mc-eui {
			display: inline-block;
			height: 176px;
			background: #252D3A;
			border-radius: 24px;
			width: 20%;
			margin-left: 2%;
			position: relative;
			top: 35px;
			vertical-align: top;
			.mc-title {
				width: 80%;
				top: 15px;
				left: 10%;
				position: relative;
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
				text-align: center;
				letter-spacing: 0.0015em;
				color: #FFFFFF;
				margin-bottom: 32px;
			}
			.mc-eui-val {
				position: relative;
				top: 15px;
				font-style: normal;
				font-weight: normal;
				font-size: 60px;
				line-height: 70px;
				left: 24px;
				text-align: left;
				letter-spacing: -0.005em;
				color: #6C5CE7;
				canvas {
					width: calc(100% - 200px);
					height: 60px;
					margin-left: 12px;
				}
			}
		}
		.mc-ghg {
			display: inline-block;
			height: 176px;
			background: #252D3A;
			border-radius: 24px;
			width: 20%;
			margin-left: 2%;
			position: relative;
			top: 35px;
			vertical-align: top;
			.mc-title {
				width: 80%;
				top: 15px;
				left: 10%;
				position: relative;
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
				text-align: center;
				letter-spacing: 0.0015em;
				color: #FFFFFF;
				margin-bottom: 32px;
			}
			.mc-ghg-val {
				position: relative;
				top: 15px;
				font-style: normal;
				font-weight: normal;
				font-size: 60px;
				line-height: 70px;
				left: 24px;
				text-align: left;
				letter-spacing: -0.005em;
				color: #FAB1A0;
				canvas {
					width: calc(100% - 200px);
					height: 60px;
					margin-left: 12px;
				}
			}
		}
  }

  .header-top {
  	position: relative;
	  .site-switcher {
		  font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 21px;
			letter-spacing: 0.1em;
			color: #E1E1E1;
			line-height: 72px;
			img {
				margin-left: 8px;
				width: 12px;
        cursor: pointer;
			}
			cursor: pointer;
	  }
    .site-switcher-open {
      position: absolute;
		  font-style: normal;
			font-weight: 500;
			font-size: 18px;
			letter-spacing: 0.1em;
			color: #55B9FC;
			line-height: 56px;
      background: #252D3A;
      border-radius: 9px;
      width: 260px;
      text-indent: 16px;
      margin-top: 8px;
      margin-left: -16px;
      padding-bottom: 16px;
      z-index: 11;
			img.up-arrow {
				margin-left: 8px;
				width: 12px;
        cursor: pointer;
			}
      img.b360-link {
        cursor: pointer;
        display: block;
        margin-left: 16px;
      }
			cursor: pointer;
	  }
	  width: 100%;
	  height: 72px;
	  border-bottom: 2px solid #252D3A;
	  .header-top-right {
	  	position: absolute;
	  	right: 48px;
	  	top: 12px;
	  	height: 36px;
	  	> img {
	  		margin-right: 36px;
	  	}
	  	.en-button-primary {
	  		margin-right: 64px;
	  		margin-top: 4px;
	  	}
	  	> .header-user-icon {
	  		display: inline-block;
	  		.header-user-avatar {
	  			position: relative;
	  			display: inline-block;
	  			width: 36px;
	  			height: 36px;
	  			background-color: #55B9FC;
	  			border-radius: 36px;
	  			color: #000;
	  			font-weight: 500;
	  			font-size: 24px;
	  			text-align: center;
	  			text-indent: -2px;
	  			top: 5px;
	  		}
	  		img {
	  			margin-left: 8px;
	  		}
	  	}
	  }
	}

  .get-advice {
  	position: fixed;
  	bottom: 31px;
  	right: 16px;
  	width: 150px;
  	z-index: 10;
    .advice-bubble {
    	position: relative;
      .bubble-left {
      	z-index: 1;
      	position: absolute;
        width: 78.49px;
				height: 30px;
				background: #273153;
				border-radius: 5px;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 14px;
				letter-spacing: 0.0004em;
				color: #FFFFFF;
				text-align: center;
				line-height: 30px;
      }
      .bubble-right {
      	z-index: 0;
      	position: absolute;
				width: 13.41px;
				height: 13.41px;
				background: #273153;
				transform: matrix(-0.71, -0.78, -0.64, 0.71, 0, 0);
				left: 69px;
				top: 7px;
      }
    }
    .advice-icon {
    	position: relative;
    	left: 95px;
    	top: -5px;
    	width: 44px;
    	height: 44px;
    	background: #55B9FC;
    	border-radius: 44px;
    	line-height: 40px;
    	text-align: center;
    	cursor: pointer;
      img {
      	width: 20px;
      	height: 20px;
      }
    }
  }
}
