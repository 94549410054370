.enbuildings-container {
  width: 100%;
  display: block;

  .page-title-white {
  	font-style: normal;
  	font-weight: 500;
  	font-size: 20px;
  	line-height: 23px;
  	letter-spacing: 0.0015em;
  	color: #FFFFFF;
  	margin-bottom: 49px;
  }

  .building-image {
  	cursor: pointer;
  }
}
