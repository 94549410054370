.insights-page {

  margin-left: -10px;
  width: calc(100% + 20px);

  .page-title-white {
    color: #FFF;
  }

  .insight-filter-cont {
    position: absolute;
    top: 32px;
    right: 34px;
    .insight-filter-button {
      vertical-align: top;
      display: inline-block;
      padding: 10px;
      height: 39px;
      min-width: 130px;
      background: #252D3A;
      border-radius: 10px;
      margin-left: 15px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.0015em;
      color: #DDDDDD;
      border: 1px solid #252D3A;
      &:hover {
        &:not(.active) {
          background: #353D4A;
        }
      }
      &:not(.active) {
        cursor: pointer;
      }
      &.active {
        border: 1px solid #55B9FC;
      }
      .insight-filter-bubble {
        position: relative;
        top: 16px;
        background: #2D3645;
        border: 1px solid #1F2631;
        box-sizing: border-box;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.24);
        border-radius: 6px;
        padding: 0px;
        width: auto;
        .insight-filter-option {
          &:hover {
            background: #3D4655;
            &.selected {
              background: #252D3A;
            }
          }
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.0025em;
          cursor: pointer;
          &.selected {
            color: #55B9FC;
            background: #252D3A;
            pointer-events: none;
            cursor: auto;
          }
          &.first {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
          }
          &.last {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }
          padding: 8px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }

  /*.insight-offer-toggle {
    margin-top: 14px;
    margin-right: 14px;
    padding: 9px;
    padding-top: 6.5px;
    padding-left: 11px;
    padding-right: 11px;
    height: 36px;
    background: #273153;
    border-radius: 10px;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.0015em;
    line-height: 16px;
    cursor: pointer;

    .insight-ot-count {
      display: inline-block;
      padding: 3px;
      padding-left: 6px;
      padding-right: 6px;
      background: #212B4E;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.001em;
      margin-left: 10px;
      border: 1px solid #303D66;
      border-radius: 5px;
      color: #FFFFFF;
    }
    &.selected {
      background: #2797FF;
      color: #FFFFFF;
      cursor: auto;
      pointer-events: none;
      .insight-ot-count {
        border: none;
        background: #74B9FF;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.001em;
        color: #273153;
      }
    }
  }*/

  .insight-container {
    width: 100%;
    display: block;
    margin-top: 31px;
  }
}