.ac-cont {
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 32px;
  box-shadow: none !important;
  .ac-header {
    cursor: pointer;
    height: 64px;
    color: #DDD;
    .ac-header-text {
      width: 100%;
      font-size: 18px;
      text-align: center;
    }
  }
  .accordion-toggle {
    i {
      font-size: 22px;
      color: #AAA;
    }
  }
}

.ac-field {
  width: 50%;
  label {
    color: #d0d0d0;
  }
}

.ac-field-list {
  margin-top: -16px;
  margin-bottom: 32px;
}

.pre-upgrade-wrapper {
  padding-right: 2em;
  margin-right: -2em;
}