h2.page-title {
    font-size: 17px;
}

label {
    font-size: 12px;
}

.rvalue-inner {
    display: none;
    input {
        width: 100% !important;
        min-width: 60px;
        height: 38px;
    }
    label {
        width: 100% !important;
        min-width: 60px;
        height: 14px;
    }
    .rv-upgrade-delete {
        cursor: pointer;
        position: relative;
        top: 12px;
        left: 12px;
    }
    .rv-section-delete {
        cursor: pointer;
        position: relative;
        top: -2px;
        left: 12px;
    }
    .selectCustomization  {
        min-width: 60px;
    }

    .col-1 {
        max-width: calc(8.3333% - 8px) !important;
        padding-left: 4px;
        padding-right: 4px;
    }
    .col-2 {
        max-width: calc(16.6666% - 8px) !important;
        padding-left: 4px;
        padding-right: 4px;
    }
    .col-3 {
        max-width: calc(25% - 8px) !important;
        text-align: left;
        padding-left: 4px;
        padding-right: 4px;
    }
    .col-4 {
        max-width: calc(33.3333% - 8px) !important;
        padding-left: 4px;
        padding-right: 4px;
    }
    .col-5 {
        max-width: calc(41.6666% - 8px) !important;
        padding-left: 4px;
        padding-right: 4px;
    }
    .col-6 {
        max-width: calc(50% - 8px) !important;
        padding-left: 4px;
        padding-right: 4px;
    }
    .col-12 {
        max-width: calc(100% + 4em) !important;
        width: calc(100% + 4em) !important;
        padding-left: 4px;
        padding-right: 0px;
    }
    .col-4 + .col-4 {
        margin-right: 2px;
        margin-left: 2px;
        padding: 0px;
    }
    label {
        padding: 4px;
    }
    .col-6 + .col-6 {
        margin-right: 2px;
        margin-left: 2px;
        padding: 0px;
    }
    .col-3 + .col-3 {
        margin-right: 2px;
        margin-left: 2px;
        padding: 0px;
    }
    .selectCustomization {
        width: calc(95%);
        height: 24px !important;
        margin-top: 6px;
        margin-bottom: 8px;
        > div {
            height: 24px;
        }
        input {
            margin-top: -4px;
            height: 24px;
        }
    }
    .col-6:nth-of-type(3n) {
        .selectCustomization {
            width: 98.5%;
        }
    }
    .react-select__menu {
        display: block;
        height: auto !important;
        margin-top: 20px;
        z-index: 2;
        .react-select__menu-list {
            .react-select__option {
            }
        }
    }
    button {
        margin-left: 4px !important;
        margin-right: 0px !important;
        height: 38px;
        min-width: 60px;
    }
}

.rsi-thresh-warning {
    color: #e67478;
    font-style: italic;
    margin-left: 24px;
}
.rsi-thresh-warning::before {
    margin-top: 20px;
    display: inline-block;
    width: 12px;
    height: 4px;
    border-radius: 2px;
    left: -18px;
    margin-right: -12px;
    top: -3px;
    position: relative;
    background-color: #e67478;
    content: '';
}

.rvalue-inner-show {
    display: block;
}

.rc-est-rebate {
    position: relative;
    left: 48px;
    top: -22px;
    color: #e67478;
    font-weight: bold;
}
.rc-est-rebate-non-rc {
    position: relative;
    left: 0px;
    top: -22px;
    color: #e67478;
    font-weight: bold;

}
.rc-est-rebate-green {
    color: #74e69b;
}

.rvalue-widget {
    margin-right: -20px;
    &.rvalue-dark-bg {
        margin-top: 1.5em;
        background: #223047;
        border-radius: 8px;
        padding-top: 1em;
        padding-bottom: 0.5em;
        padding-left: 3em;
        padding-right: 2em;
        margin-right: 0px;
        margin-left: 1.5em;
        &.rvalue-expanded {
            background: #1A2537;
            padding-bottom: 1.25em;
        }
        .page-title {
            margin-left: -1em;
            padding-right: 0px;
            background-color: transparent !important;
            &:hover {
                color: white;
            }
        }
    }
    label {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: rgba(244, 244, 245, 0.6);
    }
    .rvalue-title-error {
        color: #e67478;
    }
    .col-1 {
        padding: 0px;
    }
    .upgrade-angle {
        display: block;
        width: 50%;
        margin-left: 50%;
        height: 18px;
        border-bottom: 2px solid rgba(255, 2550, 255, 0.2);
        border-left: 2px solid rgba(255, 2550, 255, 0.2);
    }
    .upgrade-row {
        .upgrade-angle {
            display: block;
            width: 80%;
            margin-left: 50%;
            height: 48px;
            border-bottom: 2px solid rgba(255, 2550, 255, 0.2);
            border-left: 2px solid rgba(255, 2550, 255, 0.2);
        }
        .col-5 {
            margin-left: 16px;
        }
    }
    .fa-angle-down, .fa-angle-up {
        float: right;
        font-size: 24px;
        margin-top: -4px;
    }
    .rv-section {
        box-sizing: padding-box;
        padding-left: 1px;
        padding-right: 1px;
        padding-top: 18px;
        padding-bottom: 0px;
        /*border-radius: 4px;*/
        margin-bottom: 4px;
        margin-top: 32px;
        .row.form-group {
            margin-top: 6px;
            margin-left: -12px;
            padding-bottom: 0px;
        }
        .rv-section-label {
            position: relative;
            /*margin-left: -11px;*/
            font-family: Muli;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 19px;
            color: white;
            margin-top: -8px;
            margin-bottom: 18px;
            border-top: 2px solid rgba(0, 0, 0, 0.0);
            margin-left: -8px;
            img {
                margin-right: 6px;
            }
        }
        margin-left: -8px;
    }
    .rvalue-allow-collapse {
        cursor: pointer;
        padding: 4px;
        border-radius: 4px;
    }
    .rvalue-allow-collapse:hover {
        background-color: rgba(255, 255, 255, 0.15);
    }

    .selectReadOnly {
        pointer-events: none;
        opacity: 0.5;
    }

    .slim-button {
        padding: 4px;
    }

    .rvc-sqft {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: rgba(244, 244, 245, 0.18);
        margin-left: 6px;
    }

    input, select {
        margin-top: 6px;
    }

    .col-3 {
        label {
            font-size: 0.7vw;
        }
    }

    .area-percent {
        position: absolute;
        font-size: 11px;
        right: 35px;
        top: 15px;
        z-index: 1;
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: rgba(6,8,12,0.6);

        &.area-percent-error {
            color: #e67478;
        }

        &.area-percent-shift {
            //right: 22px;
        }
    }
}

.rvc-error-dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #E67478;
    position: relative;
    display: inline-block;
    top: -2px;
    margin-right: 8px;
}