.simp-hover-icon {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    .shi-icon {
        cursor: pointer;
    }
    /*width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    
    border-top: 20px solid #f00;*/
    .shi-bubble {
        filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.25));
        .shi-bubble-inner {
            padding: 12px;
            background: #374661;
            border-radius: 5px;   
            position: relative;
            top: 5px;
        }
        .shi-bubble-arrow {
            position: relative;
            display: inline-block;
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            border-top: 11px solid #374661;
        }
        text-align: center;
        position: fixed;
        z-index: 5;
    }
}