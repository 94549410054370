.create-building-modal {
	background: #1C222D;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 5;
	&.loading {
		.create-building-modal-form-cont {
			opacity: 0.5;
		}
		pointer-events: none !important;
	}
	.create-building-modal-att-header {
		font-weight: 500;
		font-size: 20px;
		line-height: 23px;
		letter-spacing: 0.0015em;
		color: #FFFFFF;
	}
	.en-att-uploader {
		margin-bottom: 16px;
	}
	.create-building-modal-header {
		margin-left: 30px;
		margin-right: 30px;
		width: calc(100% - 60px);
		border-bottom: 2px solid #252D3A;
		height: 72px;
		position: relative;
		.create-building-modal-title {
			display: inline-block;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			height: 72px;
			line-height: 72px;
			letter-spacing: 0.0015em;
   		color: #FFFFFF;
		}
		.create-building-modal-header-right {
			position: relative;
			display: inline-block;
			float: right;
			margin-top: 16px;
			.en-button-secondary {
				margin-left: 12px;
			}
			img {
				margin-left: 35px;
				cursor: pointer;
			}
		}
	}
	.create-building-modal-form-cont {
		left: 50%;
		margin-left: -334px;
		width: 668px;
		border-bottom: 2px solid #252D3A;
		overflow-y: scroll;
		overflow-x: hidden;
		height: calc(100% - 182px);
		position: absolute;
		.create-building-modal-form {
      .create-um-sub-title {
        display: block;
        font-style: normal;
  			font-weight: 500;
  			font-size: 20px;
  			height: 72px;
  			line-height: 90px;
  			letter-spacing: 0.0015em;
     		color: #dddddd;
        border-bottom: 2px solid #252D3A;
        margin-bottom: 20px;
      }
			width: 668px;
			.en-dropdown-button {
				margin-left: 0px;
			}
			.types-dropdown {
				margin-top: 35px;
				margin-left: 0px;
				margin-bottom: 21px;
			}
			.en-input-wrapper {
				margin-bottom: 16px;
			}
			.editor-container {
				margin-top: 30px;
			}
			.create-building-modal-form-row + .create-building-modal-form-row {
				margin-top: 15px;
			}
			.en-min-max + .en-min-max {
				margin-left: 16px;
			}
			.en-input-wrapper + .en-min-max {
				margin-left: 16px;
			}
			.create-building-modal-form-row {
				display: block;
			}
			.create-building-modal-form-sep {
				display: block;
				width: 100%;
				border-bottom: 1px solid #252D3A;
				margin-top: 26px;
				margin-bottom: 24px;
			}
		}
	}
	.create-building-modal-submit-buttons {
		left: 50%;
		margin-left: -334px;
		width: 668px;
		position: absolute;
		bottom: 4px;
		height: 108px;
		padding-top: 32px;
		.en-button-primary {
			margin-right: 15px;
		}
		> .en-button-secondary {
			float: right;
		}
		.revoke-button-wrapper {
			margin-left: 8px;
		}
	}
}
