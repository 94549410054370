@import '../../styles/app';

$sidebar-width-open-en: 73px;
$sidebar-width-open-ra: 200px;

.root {
  height: 100%;
  position: relative;
  left: 0;
  transition: left $sidebar-transition-time ease-in-out;
}

.active-loader {
  display: block;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.active-loader-inside {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 96px;
  height: 96px;
  margin-left: -48px;
  margin-top: -48px;
}

.active-loader-status {
  color: #cbe8f6;
  font-size: 18px;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 96px;
  margin-left: -75px;
  margin-top: 48px;
  text-align: center;
}

.wrap-en {
  position: relative;
  min-height: 100vh;
  display: flex;
  margin-left: $sidebar-width-open-en;
  flex-direction: column;
  left: 0;
  right: 0;
  transition: left $sidebar-transition-time ease-in-out;
  
  @media print {
    margin: 0;
  }

  @media (max-width: breakpoint-max(sm)) {
    margin-left: 0;
    left: 0;
  }

  :global(.sidebar-right) & {
    margin-left: 0;
    margin-right: $sidebar-width-open-en;
  }

  :global(.sidebar-hide) & {
    margin: 0;
  }
}

.wrap-ra {
  position: relative;
  min-height: 100vh;
  display: flex;
  margin-left: $sidebar-width-open-ra;
  flex-direction: column;
  left: 0;
  right: 0;
  transition: left $sidebar-transition-time ease-in-out;
  
  @media print {
    margin: 0;
  }

  @media (max-width: breakpoint-max(sm)) {
    margin-left: 0;
    left: 0;
  }

  :global(.sidebar-right) & {
    margin-left: 0;
    margin-right: $sidebar-width-open-ra;
  }

  :global(.sidebar-hide) & {
    margin: 0;
  }
}


.content {
  position: relative;
  flex-grow: 1;
  // 20px for footer height
  padding: 25px $content-padding ($content-padding + 20px);

  @media (max-width: breakpoint-max(sm)) {
    padding: 20px 15px;
  }

  // hammers disallows text selection, allowing it for large screens
  @media (min-width: breakpoint-min(sm)) {
    user-select: auto !important;
  }
  
}

.contentFooter {
  position: absolute;
  bottom: 15px;
  color: $text-muted;
}
