.crp-vcb-number-save {
  color: #367B3D !important;
}
.crp-vcb-number-cost {
  color: #C5271F !important;
}
.ra-negative-color {
  color: #C5271F !important;
}
.ra-negative-color > * {
  color: #C5271F !important;
}

.auth-page, .ea-accept-invite-page {
  .loading {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    background-color: rgba(31, 31, 31, 0.5);
    .loading-claiming-msg {
      position: absolute;
      width: 100vw;
      text-align: center;
      left: 0px;
      top: calc(50vh + 10vh);
      color: white;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding-left: 3.5rem;
      padding-right: 3.5rem;
    }
    img {
      position: absolute;
      width: 10vh;
      height: auto;
      left: calc(50vw - 5vh);
      top: calc(50vh - 5vh);
    }
    &.loading-show {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.25s, visibility 0.01s;
    }
    &.loading-hide {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transition: opacity 0.25s, visibility 0.25s;
    }
  }
}

// Public Header/Sidebar
.public-header-cont {
  *:focus {
    box-shadow: 0px 0px 0px 4px rgba(66, 86, 213, 1);
    border-radius: 5px;
  }
  button {
    border: none;
    background-color: transparent;
    padding: 0px;
    outline: none;
  }
  .loading {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    background-color: rgba(31, 31, 31, 0.5);
    .loading-claiming-msg {
      position: absolute;
      width: 100vw;
      text-align: center;
      left: 0px;
      top: calc(50vh + 10vh);
      color: white;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding-left: 3.5rem;
      padding-right: 3.5rem;
    }
    img {
      position: absolute;
      width: 10vh;
      height: auto;
      left: calc(50vw - 5vh);
      top: calc(50vh - 5vh);
    }
    &.loading-show {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.25s, visibility 0.01s;
    }
    &.loading-hide {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transition: opacity 0.25s, visibility 0.25s;
    }
  }
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  .public-header {
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 74px;
    flex-shrink: 0;
    width: 100%;
    vertical-align: top;
    border-top: 8px solid #1226AA;
    position: relative;
    z-index: 20;
    .ph-right {
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .ph-org-logo {
      max-height: 50px;
      max-width: 150px;
      margin-top: -4px;
      margin-left: 20px;
      margin-right: 10px;
    }
    .ph-text {
      margin-top: 15px;
      padding-left: 10px;
      border-left: 1px solid #979797;
      height: 31px;
      display: inline-block;
      color: #1226AA;
      font-family: Poppins;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.008rem;
      line-height: normal;
    }
    .ph-btn {
      display: inline-block;
      height: 66px;
      min-width: 105px;
      text-align: center;
      line-height: 60px;
      color: #292B2C;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.008rem;
      text-transform: uppercase;
      margin-top: -3px;
      &.ph-btn-narrow {
        width: 50px;
        min-width: 50px;
      }
      &:focus {
        border-radius: 0px;
      }
      img {
        position: relative;
        width: 24px;
        height: 24px;
      }
      cursor: pointer;
      &.phb-selected {
        background-color: rgba(18, 38, 170, 0.07);
        border-bottom: 8px solid #1226AA;
        color: #1226AA;
      }
    }
    .ph-lang-switcher {
      display: inline-block;
      cursor: pointer;
      position: relative;
      margin-right: 16px;
      .phls-btn {
        border-radius: 8px;
        padding: 7px 11px 7px 11px;
        border: 1px solid #4A3DF0;
        background: #0E22A1;
        margin-left: 32px;
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.005rem;
        &:focus {
          border-radius: 8px;
        }
        cursor: pointer;
        img {
          position: relative;

          margin-right: 4px;
        }
      }
      .phls-dropdown {
        box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.45);
        display: none;
        position: absolute;
        z-index: 20;
        background-color: white;
        border-radius: 6px;
        width: 158px;
        padding: 8px;
        top: 45px;
        left: -60px;
        &.phls-dd-show {
          display: block;
        }
        .phlsdd-lang-option + .phlsdd-lang-option {
          margin-top: 8px;
        }
        .phlsdd-lang-option {
          cursor: pointer;
          color: #242424;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.0015rem;
          &.phlsdd-lang-selected {

          }
        }
      }
    }
    .ph-signin-btn {
      display: inline-block;
      cursor: pointer;
      margin-right: 10px;
      margin-left: 44px;
      color: #292B2C;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.008rem;
      line-height: normal;
      text-transform: uppercase;
      img {
        position: relative;
      }
    }
  }
  .public-header-mobile {
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 56px;
    flex-shrink: 0;
    width: 100%;
    vertical-align: top;
    border-top: 8px solid #1226AA;
    position: relative;
    overflow-x: hidden;
    left: 0px;
    transition: left 0.5s;
    z-index: 20;
    white-space: nowrap;
    &.psb-show {
      left: 208px;
      transition: left 0.5s;
    }
    .phm-menu-btn {
      cursor: pointer;
      margin-left: 12px;
      margin-right: 12px;
      margin-top: 12px;
      position: relative;
      display: inline-block;
    }
    .phm-org-logo {
      max-width: 119px;
      max-height: 20px;
      margin-top: 0px;
      margin-right: 3px;
    }
    .phm-text {
      display: inline-block;
      color: #1226AA;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-left: 4px;
      border-left: 1px solid #979797;
      position: relative;
      top: 0px;
    }
    z-index: 2;
  }
  .public-sidebar-mobile {
    position: absolute;
    left: -208px;
    top: 0px;
    width: 208px;
    height: 100vh;
    background: #FFF;
    box-shadow: 5px 0px 7px 0px rgba(0, 0, 0, 0.09);
    opacity: 0;
    pointer-events: none;
    transition: left 0.5s, opacity 0.5s;
    z-index: 21;
    &.psb-show {
      left: 0px;
      opacity: 1.0;
      transition: left 0.5s, opacity 0.5s;
      pointer-events: initial;
    }
    .phm-sb-bottom {
      width: 100%;
      height: 130px;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
    .phm-sb-sep {
      margin-left: 16px;
      width: calc(100% - 32px);
      border-bottom: 1px solid #DDD;
    }
    .phm-btn {
      display: inline-block;
      height: 63px;
      width: 100%;
      line-height: 60px;
      color: #292B2C;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      text-transform: uppercase;
      text-align: left;
      padding-left: 16px;
      &:focus {
        border-radius: 0px;
      }
      img {
        position: relative;
        width: 24px;
        height: 24px;
      }
      cursor: pointer;
      &.phmb-selected {
        background-color: rgba(18, 38, 170, 0.07);
        border-bottom: 8px solid #1226AA;
        color: #1226AA;
      }
    }
    .phm-lang-switcher {
      display: block;
      cursor: pointer;
      position: relative;
      .phls-btn {
        border-radius: 8px;
        padding: 7px 11px 7px 11px;
        border: 1px solid #4A3DF0;
        background: #0E22A1;
        margin-left: 16px;
        color: #FFF;
        text-align: left;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.005rem;
        width: calc(100% - 32px);
        &:focus {
          border-radius: 8px;
        }
        cursor: pointer;
        img {
          position: relative;

          margin-right: 4px;
          &.arrow-right {
            position: absolute;
            right: 24px;
            top: 14px;
          }
        }
      }
      .phls-dropdown {
        box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.45);
        display: none;
        position: absolute;
        z-index: 20;
        background-color: white;
        border-radius: 6px;
        width: 158px;
        padding: 8px;
        top: -80px;
        left: 16px;
        &.phls-dd-show {
          display: block;
        }
        .phlsdd-lang-option + .phlsdd-lang-option {
          margin-top: 8px;
        }
        .phlsdd-lang-option {
          cursor: pointer;
          color: #242424;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.0015rem;
          &.phlsdd-lang-selected {

          }
        }
      }
    }
  }
  .view-cont {
    position: relative;
    width: 100vw;
    height: calc(100vh - 74px);
    left: 0px;
    top: 0px;
    transition: left 0.5s;
    &.psb-show {
      left: 208px;
      transition: left 0.5s;
    }
  }
  .signin-modal-cont {
    display: none;
    opacity: 0;
    position: fixed;
    z-index: 31;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s;
    &.show-modal {
      display: block;
      opacity: 1;
      transition: opacity 0.5s;
    }
    background-color: rgba(0, 0, 0, 0.25);
    .signin-modal {
      border-radius: 10px;
      background: #FFF;
      width: 407px;
      max-width: 100%;
      left: 50%;
      margin-left: -203px;
      position: absolute;
      top: 50%;
      margin-top: -200px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      .sm-title {
        margin-top: 15px;
        margin-left: 20px;
        margin-bottom: 16px;
        color: #1226AA;
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        width: 100%;
      }
      .sm-close {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      .sm-error-icon {
        display: inline-block;
        margin-right: 6px;
        background-color: #F53611;
        color: #FFF;
        width: 13.838px;
        height: 13.838px;
        border-radius: 50%;
        text-align: center;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 13.838px;
        vertical-align: top;
      }
      .sm-form {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        .smf-label {
          color: #2A2B30;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
        }
        .smf-input {
          outline: none;
          width: 100%;
          border: 1px solid #DADCE0;
          border-radius: 4px;
          padding: 8px;
          &::placeholder {
            color: #565656;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        &.sm-auth-error {
          .smf-label {
            color: #F53611;
          }
          .smf-input {
            border: 1px solid #F53611;
          }
        }
        .smf-sub-wrap {
          margin-top: 17px;
          width: 100%;
          text-align: center;
          .smf-sub {
            margin-top: 10px;
            padding: 7px 31px 7px 31px;
            border-radius: 10px;
            background:#74FAC0;
            margin-left: 0px;
            width: 100%;
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.005rem;
            &:focus {
              border-radius: 8px;
            }
            cursor: pointer;
          }
        }
        .smf-error {
          margin-top: 17px;
          color: #E74C3C;
          font-family: Poppins;
          font-size: 0.9375rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          .smf-error-inner {
            display: inline-block;
            width: calc(100% - 32px);
            vertical-align: top;
            margin-top: -5px;
          }
          margin-bottom: 19px;
        }
        margin-bottom: 17px;
      }
    }
  }
  @media screen and (min-width: 1038px) {
    .public-header-mobile, .public-sidebar-mobile {
      display: none;
    }
    .psb-show {
      left: 0px !important;
    }
  }
  @media screen and (max-width: 1037px) {
    .public-header {
      display: none;
    }
    .view-cont {
      height: calc(100vh - 54px);
    }
  }
  @media screen and (max-width: 500px) {
    .signin-modal {
      left: 0px !important;
      margin-left: 0px !important;
      width: calc(100% - 8px) !important;
    }
  }
}
// End Public Header/Sidebar

.en-rt-sort {
  position: relative;
  width: auto;
  cursor: pointer;
  .en-rt-sort-arrow {
    height: 12px;
    width: auto;
    display: inline-block;
    margin-left: 8px;
    top: 0px;
  }
}

// ResponsiveTable
.en-resp-table-container {
  .en-rt-search {
    float: right;
    margin-top: -42px;
    &.is-ss {
      float: none;
      margin-top: 8px;
      margin-bottom: 8px;
      .en-input-wrapper {
        margin-bottom: 8px !important;
      }
      width: 100%;
      border-bottom: 1px solid #252D3A;
    }
  }
  .en-rt-pagination {
    user-select: none;
    margin-top: 16px;
    .en-dropdown-button {
      margin-left: 0px;
      margin-right: 24px;
    }
    .en-button-primary {
      margin-left: 8px;
      margin-right: 8px;
    }
    .disabled {
      opacity: 0.2;
      pointer-events: none;
    }
    .page-label {
      color: #dddddd;
      font-style: normal;
      font-size: 0.875rem;
      line-height: 16px;
      letter-spacing: 0.0004em;
      display: inline-block;
    }
  }
}

// Modal
.en-modal-cont {
  display: none;
  &.open {
    display: block !important;
  }
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 30;
  .en-modal {
    z-index: 1;
    position: absolute;
    background: #1C222D;
    border: 1px solid #252D3A;
    box-sizing: border-box;
    box-shadow: -8px 4px 19px rgba(9, 12, 18, 0.46);
    border-radius: 10px;
    top: 123px;
    left: 50%;
    padding: 20px;
    .en-modal-title {
      font-style: normal;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 23px;
      letter-spacing: 0.0015em;
      color: #FFFFFF;
    }
    .en-modal-close {
      float: right;
      margin-top: -30px;
      margin-right: -10px;
    }
    .en-modal-sep {
      height: 0px;
      width: calc(100%);
      margin-top: 10px;
      margin-bottom: 20px;
      border-bottom: 2px solid #252D3A;
    }
    .en-modal-content {
      width: 100%;
    }
    .en-modal-btn-cont {
      height: 44px;
      .en-modal-btn + .en-modal-btn {
        margin-left: 16px;
      }
    }
  }
}

// ConfirmButton
.confirm-button-wrapper {
  display: inline-block;
  position: relative;
  .confirm-msg {
    position: absolute;
    z-index: 1;
    bottom: calc(100% + 5px);
    background-color: #1F2631;
    color: #DDDDDD;
    border-radius: 4px;
    padding: 6px;
    &.bottom {
      bottom: auto;
      top: calc(100% + 5px);
    }
    .en-input-wrapper {
      margin-top: 6px;
      margin-bottom: 6px;
      height: 65px;
      textarea {
        height: 65px;
      }
    }
  }
  .confirm-button {
    display: inline-block;
  }
  &.open {
    .confirm-button {
      padding-right: 100px;
    }
  }
  .confirm-buttons {
    display: inline-block;
    width: 87px;
    margin-left: 10px;
    position: absolute;
    padding-right: 0px !important;
    top: 0px;
    .yes-button {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      background-color: #E73C3C;
      color: black;
      border-color: transparent;
      border-top: none;
      border-bottom: none;
    }
    .no-button {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      margin-right: 0px;
      border-color: transparent;
    }
  }
}

// AttachmentUploader
.en-att-uploader {
  section {
    width: 100%;
    height: 82px;
    border: 1px dashed #252D3A;
    box-sizing: border-box;
    border-radius: 10px;
    .en-att-label {
      position: relative;
      cursor: pointer;
      left: 50%;
      margin-left: -175px;
      top: 22px;
      img {
        position: absolute;
        left: 0px;
        height: 31px;
      }
      .en-att-label-1 {
        font-style: normal;
        font-weight: bold;
        font-size: 0.75rem;
        line-height: 14px;
        letter-spacing: 0.0004em;
        color: #55B9FC;
        position: absolute;
        left: 36px;
        top: 4px
      }
      .en-att-label-2 {
        font-style: normal;
        font-weight: normal;
        font-size: 0.625rem;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.015em;
        color: #DDDDDD;
        position: absolute;
        left: 36px;
        top: 20px
      }
    }
  }
  .en-att-file {
    margin-top: 20px;
    .en-atf-left {
      display: inline-block;
      margin-right: 5px;
      img {

      }
      width: 25px;
    }
    .en-atf-right {
      width: calc(100% - 32px);
      display: inline-block;
      vertical-align: top;
      .en-atf-file-name {
        font-style: normal;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 16px;
        letter-spacing: 0.0125em;
        color: #DDDDDD;
        text-indent: 3px;
        margin-bottom: 10px;
      }
      .en-atf-back-bar {
        .en-atf-bar {
          height: 7.43px;
          border-radius: 47px;
          &.success {
            background: #20C2A2;
          }
          &.error {
            background: #FF8B7B;
          }
          &.active {
            background: #3498DB;
          }
          &.pending {
            background: #B1B9C4;
          }
        }
        background: #2D3645;
        margin-bottom: 6px;
        height: 7.43px;
        border-radius: 47px;
        width: 100%;
      }
      .en-atf-del-icon {
        float: right;
        width: 11px;
        margin-top: -16px;
        margin-right: 8px;
        cursor: pointer;
        opacity: 0.8;
        &:hover {
          opacity: 1.0;
        }
      }
      .en-atf-status {
        width: 100%;
        .en-atf-status-left {
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          font-size: 0.625rem;
          line-height: 12px;
          letter-spacing: 0.015em;
          color: #DDDDDD;
        }
        .en-atf-status-right {
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          font-size: 0.625rem;
          line-height: 12px;
          letter-spacing: 0.015em;
          &.success {
            color: #20C2A2;
            margin-right: 5%;
          }
          &.error {
            color: #FF8B7B;
            margin-right: 5%;
          }
          &.active {
            color: #C1C9D4;
          }
          &.pending {
            color: #B1B9C4;
          }
          float: right;
        }
      }
    }
  }
}

// InsightTypeBlock
.insight-type-block {
  pointer-events: none;
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.0125em;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.0125em;
  border: 1px solid #fff;
  padding-top: 6px;
  padding-bottom: 0px;
  padding-left: 14px;
  padding-right: 14px;
  width: auto;
  border-radius: 86px;
  height: 30px;
  margin-bottom: -16px;

  img {
    height: 14.6px;
    margin-right: 5.5px;
    position: relative;
    top: -3px;
  }
}

// StatLine
.insight-stat-cont {
  img {
    margin-right: 12.3px;
    margin-top: 8px;
    width: 32px;
  }
  margin-top: 10px;
  .insight-stat-line {
    display: inline-block;
    background: #7A828D;
    border-radius: 5px;
    width: 4px;
    height: 34px;
    margin-right: 10px;
    vertical-align: top;
    margin-top: 4px;
  }
  .insight-stat-inner-cont {
    vertical-align: top;
    display: inline-block;
    .insight-stat-title {
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 16px;
      letter-spacing: 0.0004em;
      color: #7A828D;
      margin-top: 2px;
      margin-bottom: 4px;
    }
    .insight-stat-subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 16px;
      letter-spacing: 0.0025em;
      color: #DDDDDD;
    }
  }
}

// BuildingImage
.building-details-image {
  display: block;
  border-radius: 15px;
  img {
    border-radius: 15px;
  }
  overflow: hidden;
}

// CompletenessSlider
.completeness-slider-large {
  width: 100%;
  .slider-outer {
    display: inline-block;
    background: #1F2631;
    border-radius: 75px;
    width: calc(100% - 80px);
    height: 25px;
    position: relative;
    .slider-inner {
      position: relative;
      background: linear-gradient(47.97deg, #64CCFF 25.71%, #26FFCB 84.2%);
      border-radius: 52px;
      height: 13px;
      top: 6px;
      left: 7px;
    }
    vertical-align: middle;
  }
  .slider-percent {
    display: inline-block;
    margin-left: 11px;
    width: 58px;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    letter-spacing: 0.0015em;
    color: #DDDDDD;
    vertical-align: middle;
  }
}

// Dropdown
.en-dropdown-button {
  user-select: none;
  &.read-only {
    pointer-events: none !important;
  }
  z-index: 1;
  vertical-align: top;
  display: inline-block;
  padding: 10px;
  height: 39px;
  min-width: 130px;
  background: #252D3A;
  border-radius: 10px;
  margin-left: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 16px;
  letter-spacing: 0.0025em;
  color: #AAB2BD;
  border: 1px solid #252D3A;
  position: relative;
  &:hover {
    &:not(.active) {
      background: #353D4A;
    }
  }
  &:not(.active) {
    cursor: pointer;
  }
  &.active {
    border: 1px solid #55B9FC;
  }
  > .insight-type-block {
    position: relative;
    top: -7px;
  }
  &.types-dropdown {
    width: 235.5px;
    color: #AAB2BD;
    border: 1px solid #2D3645;
    &.active {
      border: 1px solid #55B9FC;
    }
  }
  img.dropdown-arrow {
    position: absolute;
    top: 16px;
    right: 11px;
  }
  .en-dropdown-arrow-icon {
    position: absolute;
    pointer-events: none;
    right: 16px;
    top: 16px;
  }

  .en-dropdown-filter-input {
    border: 0;
    background: transparent;
    box-shadow: none;
    outline: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    letter-spacing: 0.001em;
    color: #AAB2BD;
    &input {
      height: 36px;
    }
    padding-left: 14px;
    margin-top: 8px;
    &:focus {
      border: 0;
      outline: 0;
      background: transparent;
      box-shadow: none;
      caret-color: #AAB2BD;
    }
    width: 100%;
  }
  .en-dd-bubble {
    top: 16px;
    margin-top: 15px;
    &.en-dd-open-up {
      position: absolute;
      top: auto;
      bottom: 38px;
      margin-top: 0px;
    }
    background: #2D3645;
    border: 1px solid #1F2631;
    box-sizing: border-box;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.24);
    border-radius: 6px;
    padding: 0px;
    width: auto;
    z-index: 1;
    max-height: 350px;
    overflow-y: scroll;
    .en-dd-option {
      &:hover {
        background: #3D4655;
        &.selected {
          background: #252D3A;
        }
      }
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.0025em;
      cursor: pointer;
      > .insight-type-block {
        position: relative;
        top: -2px;
      }
      &.selected {
        color: #55B9FC;
        background: #252D3A;
        pointer-events: none;
        cursor: auto;
      }
      &.first {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &.last {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      padding: 8px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &.en-ddb-etl-type {
    background-color: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 56px;
    text-align: left;
    margin-left: -10px;
    width: 200px !important;
    letter-spacing: 0.0015em;
    color: #DDDDDD;
    border: none;
    height: 76px;
    z-index: 10 !important;
    &:hover {
      background-color: transparent;
      color: white;
    }
    .en-dd-bubble {
      margin-top: -10px;
    }
    .en-dropdown-arrow {
      float: right;
      margin-top: 24px;
      width: 15px;
      height: auto;
      margin-right: 15px;
    }
  }
}

// MinMaxInput
.en-min-max {
  margin-left: 0px;
  z-index: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 16px;
  letter-spacing: 0.0025em;
  color: #AAB2BD;
  border: 1px solid #2D3645;
  .en-input-required {
    color: #e74c3c;
    font-weight: 800;
    position: relative;
    left: 7px;
    top: 4px;
  }
  .en-dd-bubble {
    overflow-y: hidden !important;
    z-index: 3 !important;
    width: 281px;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 58px;
    text-align: center;
    letter-spacing: 0.0004em;
    height: 58px;
    margin-left: -10px;
    &.right-bubble {
      right: 57%;
    }
    input {
      display: inline-block;
      background: #252D3A;
      border: 1px solid #2D3645;
      box-sizing: border-box;
      border-radius: 10px;
      width: 99px;
      height: 36px;
      position: relative;
      outline: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 16px;
      letter-spacing: 0.001em;
      color: #AAB2BD;
      padding-left: 8px;
      &:focus {
        border: 1px solid #55B9FC;
        outline: 0;
        box-shadow: none;
        caret-color: #AAB2BD;
      }
    }
    .mm-from-input {
      margin-left: 5px;
      margin-right: 10px;
    }
    .mm-to-input {
      margin-left: 5px;
    }
  }
}

// Input
.en-input-wrapper {
  z-index: 0;
  display: inline-block;
  width: 100%;
  background: #252D3A;
  border: 1px solid #2D3645;
  &.focused {
    border: 1px solid #55B9FC;
  }
  box-sizing: border-box;
  border-radius: 10px;
  height: 36px;
  position: relative;
  input, textarea {
    border: 0;
    background: transparent;
    box-shadow: none;
    outline: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    letter-spacing: 0.001em;
    color: #AAB2BD;
    &input {
      height: 36px;
    }
    padding-left: 14px;
    margin-top: 8px;
    &:focus {
      border: 0;
      outline: 0;
      background: transparent;
      box-shadow: none;
      caret-color: #AAB2BD;
    }
    width: 100%;
  }
  .en-input-vlabel {
    display: inline-block;
    pointer-events: none;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    letter-spacing: 0.001em;
    color: #AAB2BD;
    height: 42px;
    padding-left: 14px;
    margin-right: -5px;
    position: relative;
  }
  &.en-input-large {
    input {
      margin-top: -1px;
    }
    .en-input-vlabel {
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 100%;
      letter-spacing: 0.0025em;
    }
  }
  &.has-value {
    margin-bottom: 10px !important;
    input, textarea {
      display: inline-block;
      width: auto !important;
      position: relative;
    }
  }
  .en-input-label {
    pointer-events: none;
    position: absolute;
    top: 9px;
    left: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    letter-spacing: 0.001em;
    color: #AAB2BD;
    height: 42px;
    padding-left: 14px;
    .en-input-required {
      color: #e74c3c;
      font-weight: 800;
      position: relative;
      left: 7px;
      top: 4px;
    }
  }
  &.en-input-large {
    height: 42px;
    input, textarea {
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 100%;
      letter-spacing: 0.0025em;
      height: 42px;
      margin-top: -1px;
    }
    .en-input-label {
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 100%;
      letter-spacing: 0.0025em;
      top: 7px;
    }
  }
}

// TabBuilder
.en-tab-builder {
  width: 645px;
  height: 45px;
  margin-top: 27px;
  border-bottom: 1px solid #252D3A;
  .insight-tab {
    height: 45px;
    display: inline-block;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 35px;
    letter-spacing: 0.001em;
    cursor: pointer;
    color: #DDDDDD;
    span.opt-required {
      color: #e74c3c;
      font-weight: bold;
      margin-left: 4px;
      position: relative;
      top: 0px;
      font-size: 1rem;
    }
    &.tab-selected {
      cursor: initial;
      pointer-events: none;
      color: #3498DB;
      border-bottom: 3px solid #3498DB;
    }
    img {
      width: 9.3px;
      height: auto;
      margin-left: 7.3px;
      cursor: pointer;
      pointer-events: auto;
    }
  }
  .add-button {
    background: #55B9FC;
    border-radius: 10px;
    width: 28px;
    height: 28px;
    float: right;
    margin-top: 2px;
    position: relative;
    z-index: 2;
    img {
      width: 10.8px;
      height: auto;
      position: relative;
      left: 9px;
      top: 2px;
    }
    cursor: pointer;
    &:hover {
      background: #65C9FF;
    }
    &.disabled {
      background: #252D3A;
      pointer-events: none;
      img {
        opacity: 0.5;
      }
    }
    .en-dd-bubble {
      position: absolute;
      top: 42px;
      right: 0px;
      background: #2D3645;
      border: 1px solid #1F2631;
      box-sizing: border-box;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.24);
      border-radius: 6px;
      padding: 0px;
      width: 145px;
      padding-top: 5px;
      padding-bottom: 5px;
      .en-dd-option {
        &:hover {
          background: #3D4655;
        }
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 16px;
        letter-spacing: 0.0025em;
        color: #DDDDDD;
        cursor: pointer;
        &.first {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
        &.last {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        padding: 5px;
        padding-left: 11px;
        padding-right: 11px;
      }
    }
  }
}

// RichTextEditor
.editor-container {
  position: relative;
}
.editor-wrapper {
  border-radius: 10px;
}
.editor-editor {
  background: #252D3A;
  border: 1px solid #2D3645;
  box-sizing: border-box;
  border-radius: 10px;

  margin-top: 13px;

  color: #DDDDDD;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 300px;
  max-height: 300px !important;
  overflow-y: scroll !important;
  .public-DraftStyleDefault-block {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.editor-toolbar {
  border: none;
  color: #000;
  background: #1F2631;
  border-radius: 10px;
  padding: 8px;
  .rdw-fontfamily-wrapper {
    display: none;
  }
  .rdw-fontsize-wrapper {
    display: none;
  }
  .en-dd-bubble {
    max-height: 165px;
    width: 100px !important;
    overflow: hidden;
    > div {
      width: calc(100% + 16px);
    }
  }
  .en-dropdown-button {
    min-width: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 14px;
    letter-spacing: 0.0004em;
    color: #DDDDDD;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    height: 25px;
    background: #252D3A;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 3px;
  }
  .rte-inline-btns {
    margin-left: -8px;
  }
  .rte-text-align-btns {
    margin-right: -16px;
  }
  .en-dd-bubble {
    width: 120px;
    left: -10px;
  }
  .rte-button-icon {
    padding-top: 8px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 8px;
    width: auto;
    cursor: pointer;
    opacity: 0.7;
    &.selected {
      opacity: 1.0;
    }
    &:hover {
      opacity: 0.95;
    }
  }
}

// RichTextViewer
.djs-viewer-container {
  background: transparent;
  color: #DDDDDD;
}
.djs-viewer-wrapper {
  background: transparent;
}
.djs-viewer-editor {
  margin-top: 12px;
  margin-bottom: 12px;
  color: #DDDDDD;
  background: transparent;
  .public-DraftStyleDefault-block {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  br {
    display: block;
  }
  /*line-height: 8px;*/
}
.djs-viewer-toolbar {
  display: none;
}

// Buttons
.en-button-primary {
  user-select: none;
  display: inline-block;
  padding: 10px;
  background: #55B9FC;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 16px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  border: 1px solid transparent;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &:hover {
    background: #65C9FF;
  }
}

.en-button-transparent {
  user-select: none;
  display: inline-block;
  padding: 10px;
  background: transparent;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 16px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  border: 1px solid transparent;
  cursor: pointer;
}

.en-button-secondary {
  user-select: none;
  display: inline-block;
  background: transparent;
  border-radius: 10px;
  padding: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 16px;
  letter-spacing: 0.0125em;
  color: #55B9FC;
  border: 1px solid #55B9FC;
  cursor: pointer;
  &:hover {
    color: #65C9FF;
    border: 1px solid #65C9FF;
  }
}

.en-button-secondary-red {
  user-select: none;
  display: inline-block;
  background: transparent;
  border-radius: 10px;
  padding: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 16px;
  letter-spacing: 0.0125em;
  color: #F75C4C;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    color: #FF6C5C;
  }
}
.confirm-button-wrapper.open > .en-button-secondary-red {
  border: 1px solid #F75C4C;
}


.en-button-secondary-yellow {
  user-select: none;
  display: inline-block;
  background: transparent;
  border-radius: 10px;
  padding: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 16px;
  letter-spacing: 0.0125em;
  color: #E7E73C;
  border: 1px solid #E7E73C;
  cursor: pointer;
  &:hover {
    color: #F7F74C;
    border: 1px solid #F7F74C;
  }
}

.en-button-link {
  user-select: none;
  display: inline-block;
  border-radius: 10px;
  padding: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 16px;
  letter-spacing: 0.0125em;
  text-decoration-line: underline;
  cursor: pointer;
  color: #55B9FC;
  &:hover {
    color: #65C9FF;
  }
}

.form-error-border {
  border: 1px solid #E74C3C !important;
  border-radius: 10px;
  input {
    border: 0px !important;
  }
  textarea {
    border: 0px !important;
  }
}
.form-error-message, .form-error-message-rel {
  position: absolute;
  left: 0px;
  bottom: -14px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.625rem;
  line-height: 12px;
  letter-spacing: 0.015em;
  color: #FF8B7B;
  &.form-error-message-rel {
    position: relative;
    bottom: 0px;
    &.large {
      font-size: 0.8125rem;
      margin-bottom: 10px;
      margin-top: -10px;
    }
  }

}

.ph-signin-options {
  padding: 1.5rem;
  padding-top: 0px;
  .ph-signin-ext-btn {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
    width: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    margin-bottom: 14px;
    color: #000;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.02rem;
    &:active {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
    }
    &:focus {
      box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31) !important;
    }
    img {
      margin-right: 10px;
    }
  }
}

.ph-switch-cont {
  margin-top: 8px;
  color: #141D44;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  button {
    color: #00874A;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    text-decoration-line: underline;
  }
}
.smf-sub-wrap + .ph-switch-cont {
  margin-top: 25px;
}

.en-toggle-button {
  margin-top: 16px;
  img {
    display: inline-block;
    margin-right: 17px;
    vertical-align: top;
  }
  .en-toggle-button-msg {
    vertical-align: top;
    display: inline-block;
    text-align: left;
  }
}

// scrollbars
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1074B7;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2084C7;
}

.ea-faq-link {
  margin-left: 16px;
  margin-top: 10px;
  font-size: 0.75rem;
}