.bld-card {
  display: block;
  background: #252D3A;
  border-radius: 5px;
  padding: 8px;
  padding-right: 0px;
  width: 100%;
  margin-right: 30px;
  margin-bottom: 2px;
  vertical-align: top;

  &.table-header {
    background: #000000;
    .bld-header-cell {
      color: #dddddd;
      vertical-align: top;
    }
    .bld-header-split-top {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.005em;
      margin-left: 5px;
      max-width: 95%;
      width: 95%;
      color: #DDDDDD;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .bld-header-split-bottom {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.0004em;
      color: #9AA2AD;
      margin-top: 1px;
      margin-bottom: 1px;
      margin-left: 5px;
      max-width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .bld-header-split-bottom-2 {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.0004em;
      color: #7A828D;
      margin-top: 1px;
      margin-bottom: 2px;
      margin-left: 5px;
      max-width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .bld-cell-split-top {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    margin-left: 5px;
    max-width: 95%;
    width: 95%;
    color: #DDDDDD;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bld-cell-split-bottom {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.0004em;
    color: #9AA2AD;
    margin-top: 1px;
    margin-bottom: 2px;
    margin-left: 5px;
    max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bld-cell-split-bottom-2 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.0004em;
    color: #7A828D;
    margin-top: 1px;
    margin-bottom: 2px;
    margin-left: 5px;
    max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bld-cell {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.0004em;
    color: #DDDDDD;
    margin-top: 2px;
    margin-bottom: 4px;
    max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bc-sep {
    opacity: 0.12;
    width: 207px;
    height: 1px;
    background-color: #7A828D;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
