.customer-card {
  display: inline-block;
  background: #252D3A;
  border-radius: 24px;
  padding: 22px;
  padding-right: 0px;
  width: 300px;
  height: 304px;
  margin-right: 30px;
  margin-bottom: 30px;

  .customer-logo-cont {
    position: relative;
    display: inline-block;
    border-radius: 10px;
    width: 64px;
    height: 64px;
    line-height: 64px;
    background: #FFFFFF;
    cursor: pointer;
    text-align: center;
    img {
      display: inline-block;
      width: 64px;
      height: auto;
      border-radius: 10px;
      pointer-events: none;
      position: relative;
      vertical-align: middle;
      margin-top: -2px;
    }
    overflow: hidden;
  }

  .customer-goals-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #DDDDDD;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .customer-goal {
    margin-top: 7px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0025em;
    color: #FFFFFF;
    width: 225px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    &.link {
      text-decoration-line: underline;
      cursor: pointer;
    }
    .cg-bullet {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #55B9FC;
      border-radius: 20px;
      margin-right: 10px;
      &.hidden {
        opacity: 0.0;
      }
    }
  }

  .customer-name {
    position: relative;
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    margin-left: 20px;
    top: -30px;
    max-width: 175px;
    width: 175px;
    color: #DDDDDD;
  }

  .customer-offer-count {
    font-style: Roboto;
    font-weight: 500;
    font-size: 13.5px;
    line-height: 18.75px;
    letter-spacing: 0.0025em;
    color: #DDDDDD;
    margin-top: 4px;
    margin-bottom: 4px;
    div {
      display: inline-block;
    }
    .co-num-offer {
      color: #b8e994;
    }
    .co-num-insight {
      color: #ffbe76;
    }
  }

  .customer-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0004em;
    color: #7A828D;
    margin-top: 2px;
    margin-bottom: 4px;
  }

  .bc-sep {
    opacity: 0.12;
    width: 257px;
    height: 1px;
    background-color: #7A828D;
    margin-top: 26px;
    margin-bottom: 19px;
  }
}
