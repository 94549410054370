.customer-details-card {
  display: inline-block;
  background: #252D3A;
  border-radius: 24px;
  padding: 20px;
  padding-right: 0px;
  width: 322px;
  height: auto;
  margin-right: 0px;
  margin-bottom: 30px;

  .customer-logo-cont {
    display: inline-block;
    border-radius: 15px;
    width: 64px;
    height: 64px;
    background: #FFFFFF;
    pointer-events: none;
    text-align: center;
    img {
      display: inline-block;
      width: auto;
      height: 100%;
      border-radius: 15px;
      vertical-align: middle;
    }
    overflow: hidden;
  }

  .company-address {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0025em;
    color: #FFFFFF;
  }

  .bc-sep {
    opacity: 0.12;
    width: 257px;
    height: 1px;
    background-color: #7A828D;
    margin-top: 26px;
    margin-bottom: 19px;
  }

  .bd-name {
    position: relative;
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #DDDDDD;
    margin-left: 20px;
    top: -23px;
    width: 200px;
  }

  .bd-actions {
    margin-top: 20px;
    margin-bottom: 20px;
    .en-button-primary {
      margin-right: 10px;
    }
    .en-button-link {
      padding-right: 0px;
    }
  }

  .bd-details-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #DDDDDD;
    margin-bottom: 5px;
    margin-top: 42px;
  }

  .bd-detail-half {
    display: inline-block;
    width: 40%;
    margin-top: 12px;
    &.left {
      width: 60%;
    }
    .bdd-title {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.0025em;
      color: #7A828D;
      margin-bottom: 4px;
    }
    .bdd-value {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17.6px;
      letter-spacing: 0.0025em;
      color: #FFFFFF;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .bd-detail-full {
    display: block;
    width: 90%;
    margin-top: 12px;
    .bdd-title {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.0025em;
      color: #7A828D;
      margin-bottom: 8px;
    }
    .bdd-value {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17.6px;
      letter-spacing: 0.0025em;
      color: #FFFFFF;
    }
  }

  .have-access-to {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #7A828D;
    margin-top: 40px;
    margin-bottom: 3px;
  }

  .goals-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #7A828D;
    margin-top: 24px;
    margin-bottom: 15px;
  }

  .customer-goal {
    margin-top: 7px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0025em;
    color: #FFFFFF;
    width: 225px;
    margin-left: 20px;
    &.link {
      text-decoration-line: underline;
      cursor: pointer;
    }
    .cg-bullet {
      position: relative;
      margin-left: -20px;
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #55B9FC;
      border-radius: 20px;
      margin-right: 10px;
      &.hidden {
        opacity: 0.0;
      }
    }
  }

  .links-label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.005em;
    color: #7A828D;
    margin-top: 24px;
    margin-bottom: 5px;
  }

  .links {
    width: 95%;
    display: block;
    text-align: justify;
    a {
      width: 25%;
      display: inline-block;
      color: #55b9fc;
      text-decoration: underline;
    }
  }

  .bd-goals-objectives {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #7A828D;
    margin-top: 20px;
  }
  .bd-goals-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0025em;
    color: #FFFFFF;
    margin-top: 7px;
    width: 100%;
    white-space: pre-line;
  }

  .bd-pc-title {
    margin-top: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #DDDDDD;
  }

  .completeness-slider-large {
    margin-top: 11px;
  }

  .bd-pc-message {
    width: calc(100% - 20px);
    margin-top: 11px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0025em;
    color: #FFFFFF;
  }

  .insight-stat-cont {
    margin-top: 20px;
  }

  .bd-actions-row {
    margin-top: 20px;
    .bd-share-button {
      padding: 10px;
      background: #55B9FC;
      border-radius: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.0125em;
      color: #FFFFFF;
    }
  }
}
