.encustomers-container {
  width: 100%;
  display: block;

  .page-title-white {
    position: relative;
  	font-style: normal;
  	font-weight: 500;
  	font-size: 20px;
  	line-height: 23px;
  	letter-spacing: 0.0015em;
  	color: #FFFFFF;
  	margin-bottom: 30px;
    .cust-count {
      position: relative;
      display: inline-block;
      margin-left: 10px;
      padding-left: 9px;
      padding-right: 9px;
      padding-top: 1px;
      padding-bottom: 1px;
      background: #252D3A;
      border-radius: 18px;
      top: 2px;
    }
  }

  label {
    color: #FFFFFF;
    font-size: 16px;
    margin-top: 8px;
  }

  h3 {
    color: #FFFFFF;
    font-size: 18px;
  }

  h4 {
    color: #FFFFFF;
    font-size: 17px;
  }

  .building-image {
  	cursor: pointer;
  }

  input[type='file'] {
    color: #DDD;
  }

  .ent-logo-preview {
    color: #ddd;
    padding-top: 16px;
    img {
      background-color: #ffffff;
      border: 2px solid #aaa;
      max-width: 400px;
      height: auto;
    }
  }

  .ent-save-button {
    display: inline-block;
    padding: 10px;
    background: #55B9FC;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    cursor: pointer;
  }

  .ent-saved {
    padding-top: 4px;
    color: #aaffaa;
    font-size: 15px;
  }
}
