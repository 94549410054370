.simp-toggle {
    font-family: 'Muli';
    position: relative;
    display: inline-block;
    width: 45px;
    height: 24px;
    background: #087A89;
    border-radius: 94px;
    cursor: pointer;
    .st-modal {
        cursor: initial;
        position: absolute;
        bottom: 40px;
        width: 468px;
        background: #29364E;
        border-radius: 6px;
        padding: 25px;
        left: -160px;
        -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.35); 
        box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.35);
        .stm-title {
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 19px;
            color: #FFFFFF;
        }
        .stm-desc {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #FFFFFF;
            margin-top: 16px;
            margin-bottom: 35px;
        }
        .stm-close {
            position: absolute;
            top: 25px;
            right: 25px;
            cursor: pointer;
        }
        .stm-yes {
            display: inline-block;
            width: 93px;
            height: 44px;
            left: 815px;
            top: 401px;
            background: #71D6E3;
            border-radius: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 19px;
            text-align: center;
            color: #1A2536;
            line-height: 42px;
            margin-right: 19px;
            cursor: pointer;
        }
        .stm-no {
            display: inline-block;
            width: 93px;
            height: 44px;
            border: 1px solid #71D6E3;
            border-radius: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 19px;
            text-align: center;
            color: #71D6E3;
            line-height: 42px;
            margin-right: 19px;
            cursor: pointer;
        }
    }
    .toggle-circle {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 24px;
        height: 24px;
        background: #71D6E3;
        border-radius: 94px;
        opacity: 0.25;
        &.checked {
            left: calc(45px - 24px);
            opacity: 1.0;
        }
    }
}