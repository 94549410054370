.building-card {
  display: inline-block;
  background: #252D3A;
  border-radius: 24px;
  padding: 22px;
  padding-right: 0px;
  width: 300px;
  height: 372px;
  margin-right: 30px;
  margin-bottom: 30px;

  .building-name {
    margin-top: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #DDDDDD;
  }

  .building-offer-count {
    font-style: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 18.75px;
    letter-spacing: 0.0025em;
    color: #DDDDDD;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .building-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0004em;
    color: #7A828D;
    margin-top: 2px;
    margin-bottom: 4px;
  }

  .bc-sep {
    opacity: 0.12;
    width: 257px;
    height: 1px;
    background-color: #7A828D;
    margin-top: 26px;
    margin-bottom: 19px;
  }
}
