.he-input-cont {
    .he-input-label {
        color: #aab0b5;
        float: left;
    }
    .he-toggle {
        float: right;
        padding: 0px;
        background-color: transparent;
        border: none;
        &.he-toggle-left-selected {
            .he-toggle-left {
                background-color: #44d9e6;
                color: black;
            }
            .he-toggle-right {
                background-color: transparent;
                color: #44d9e6;
            }
        }

        .he-toggle-left {
            display: inline-block;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            border-top: 1px solid #44d9e6;
            border-bottom: 1px solid #44d9e6;
            border-left: 1px solid #44d9e6;
            background-color: transparent;
            color: #44d9e6;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 10px;
            padding-right: 10px;
        }

        .he-toggle-right {
            display: inline-block;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            border-top: 1px solid #44d9e6;
            border-bottom: 1px solid #44d9e6;
            border-right: 1px solid #44d9e6;
            background-color: #44d9e6;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 10px;
            padding-right: 10px;
            color: black;
        }      
    }
    .he_input {

    }
    .he-input-warning {
        margin-top: 5px;
        color: #d9d9a0
    }
}