.est-area {
    display: inline-block;
    position: relative;
    height: 16px;
    width: 16px !important;
    margin-left: 8px;
    .est-area-icon {
        position: absolute;
        cursor: pointer;
        height: 16px;
        top: 2px;
        &:hover {
            opacity: 0.7;
        }
    }
    .est-area-tooltip {
        position: absolute;
        width: 150px;
        text-align: center;
        border: 1px solid #44d9e6;
        border-radius: 3px;
        bottom: 20px;
        margin-left: -67px;
        z-index: 5;
        background-color: #233149;
        padding: 4px;
    }
    &.ea-disabled {
        .est-area-icon {
            opacity: 0.25 !important;
            cursor: initial;
        }
        .est-area-tooltip {
            border: 1px solid #e64444;
            background-color: #493123;
        }
    }
}