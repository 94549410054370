.building-attachments {
  margin-top: 30px;
  vertical-align: top;
  display: block !important;
  width: calc(100%) !important;
  background: #252D3A;
  border-radius: 24px;
  padding: 30px;
  &.expanded {
    min-height: 222px;
  }
  .file-cont {
    width: 100%;
    display: block;
    margin-top: 40px;
    .file {
      display: inline-block;
      background: #2D3645;
      border-radius: 12px;
      width: 280px;
      height: 64px;
      margin-right: 23px;
      margin-bottom: 25px;
      padding-top: 16px;
      > img {
        width: 16px;
        height: auto;
        margin-left: 23px;
        vertical-align: top;
      }
      .file-right {
        display: inline-block;
        vertical-align: top;
        margin-left: 11px;
        .file-name {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.0015em;
          color: #55B9FC;
          margin-bottom: 3px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 180px;
        }
        .file-info {
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.015em;
          color: #DDDDDD;
        }
      }
      a {
        float: right;
        position: relative;
        left: -23px;
      }
    }
  }
  .nf-cont {
    width: 100%;
    display: block;
    margin-top: 40px;
    .no-files-1 {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.0015em;
      color: #FFFFFF;
      width: 100%;
      text-align: center;
      display: block;
    }
    .no-files-2 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.0025em;
      color: #AAB2BD;
      margin-top: 8px;
      width: 100%;
      text-align: center;
      display: block;
    }
  }
  .ba-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #FFFFFF;
  }
  .float-right {
    position: relative;
    margin-top: -30px;
    float: right;
  }
  .dd-arrow {
    width: 22px;
    height: auto;
    top: 12px;
    cursor: pointer;
  }
  .en-button-primary.float-right {
    left: -36px;
  }
  .en-modal-content {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .en-att-uploader {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
