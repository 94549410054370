.mapboxgl-popup {
  max-width: 500px !important;
}

.toggle-lines-button {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }
}

.insights-map-page-cont {
  width: 100%;
  height: calc(100vh - 200px);
  .filters {
    margin-top: 15px;
    zoom: 75%;
    color: #DDDDDD;
    position: absolute;
    right: 40px;
    top: 16px;
    line-height: 40px;
    .flow-checkkbox-wrapper {
      margin-right: 20px;
    }
    .en-input-wrapper {
      width: 120px;
      margin-right: 20px;
    }
  }
}

.ace_gutter {
  z-index: 1 !important;
}

.mapboxgl-popup-content {
  background-color: #252D3A !important;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.25);
  width: 500px;
  b {
    color: #fff;
    font-size: 11px;
    display: block;
  }
  .mapboxgl-popup-close-button {
    color: #ddd;
    font-size: 16px;
    border: none !important;
    &:focus { outline: none; }
  }
}
.mapboxgl-popup-tip {
  border-top-color: #252D3A !important;
  border-bottom-color: #252D3A !important;
}

.flow-map-status {
  color: white;
  font-size: 14px;
  position: absolute;
  z-index: 10;
  top: 15px;
  left: 15px;
  text-shadow: 2px 2px #000000;
}

.flow-button-bg + .flow-button-bg {
  margin-left: 16px;
}
.flow-button-bg {
  display: inline-block;
  background: #55B9FC;
  border-radius: 10px;
  height: 32px;
  position: relative;
  z-index: 9;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.001em;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 8px;
  color: #FFFFFF;
  img {
    height: 9.33px;
    margin-top: -3px;
    margin-right: 2px;
  }
  &.add-block {
    left: 14px;
    top: 26px;
  }
  &:hover {
    background-color: #80ccff;
  }
}

.flow-button {
  display: inline-block;
  height: 36px;
  background: #252D3A;
  border: 1px solid #343E50;
  box-sizing: border-box;
  border-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #55B9FC;
  font-weight: 500;
  img {
    height: 13px;
    margin-top: -3px;
    margin-right: 3px;
  }
  margin-left: 13px;
  cursor: pointer;
  &:hover {
    border: 1px solid #55B9FC;
  }
}

.flow-file-input {
  display: none;
}

.flow-checkkbox-wrapper {
  cursor: pointer;
  display: inline-block;
  pointer-events: initial;
  .fcw-title {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.0004em;
    color: #DDDDDD;
  }
  img {
    margin-right: 6px;
    margin-top: -2px;
    height: 16px;
  }
}

.stream-job-modal {
  .flow-file-input-button {
    width: 110px !important;
    padding: 10px !important;
    height: auto !important;
  }
  height: 450px !important;
}

.manage-datasets-modal {
  .flow-file-input-button {
    width: 110px !important;
    padding: 10px !important;
    height: auto !important;
  }
  height: 450px !important;
  .flow-button-og {
    z-index: 1 !important;
  }
}

.bgj-is-stream {
  color: #ffff44 !important;
}

.flow-stream-output {
  margin-top: 20px;
  margin-left: 16px;
  > div {
    height: 30px;
    i {
      font-weight: bold;
      letter-spacing: 0.0256em;
    }
  }
  a {
    text-decoration: none;
    display: inline-block;
    border: 1px solid #343E50;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    letter-spacing: 0.0004em;
    color: #55B9FC;
    cursor: pointer;
    &:hover {
      border: 1px solid #55B9FC;
    }
  }
  a + a {
    margin-left: 10px;
  }
}

.flow-sa-edit-cont {
  color: #ffffff;
}

.flow-file-upload-cont, .flow-sa-edit-cont {
  margin-top: -16px;
  pointer-events: initial;
  .en-dropdown-button {
    margin-left: 0px;
    margin-bottom: 8px;
  }
  .flow-button-og {
    z-index: 1 !important;
  }
  .flow-file-input-button {
    display: inline-block;
    border: 1px solid #343E50;
    box-sizing: border-box;
    border-radius: 8px;
    width: 92px;
    height: 26px;
    padding: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    letter-spacing: 0.0004em;
    color: #55B9FC;
    cursor: pointer;
    &:hover {
      border: 1px solid #55B9FC;
    }
    img {
      height: 11px;
      margin-top: -4px;
      margin-right: 5px;
    }
  }
  .flow-file-types {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.0004em;
    color: #FFFFFF;
    margin-left: 13px;
  }
  .flow-file-label {
    border-top: 1px solid #343E50;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;
    color: #FFFFFF;
    padding-top: 18px;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
    img {
      height: 12px;
      margin-top: -3px;
      margin-right: 10px;
    }
  }
}

.flow-modal-cont {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  .flow-modal {
    pointer-events: initial;
    position: fixed;
    width: 862px;
    height: 625px;
    top: 97px;
    left: calc(50% - 431px);
    &.export-modal {
      width: 432px;
      height: 270px;
      left: calc(50% - 216px);
      .flow-button {
        float: right;
        margin-left: 0px;
        margin-top: 32px;
      }
    }
    background: #1C222D;
    border-radius: 10px;
    z-index: 21;
    padding: 32px;
    .fm-title-large {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.0015em;
      color: #DDDDDD;
    }
    .fm-title {
      margin-top: 30px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.001em;
      color: #DDDDDD;
      padding-bottom: 17px;
    }
    .fm-half {
      display: inline-block;
      width: calc(50% - 10px);
      margin-right: 10px;
      vertical-align: top;
      .en-dropdown-button {
        width: 100%;
      }
    }
    .fm-job-slicing {
      width: 100%;
      height: 280px;
      overflow-y: scroll;
      border-bottom: 1px solid #252D3A;
      .bg-js-file {
        width: calc(50% - 20px);
        height: 250px;
        background: #2D3645;
        border: 1px solid #404C5F;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        display: inline-block;
        vertical-align: top;
        margin-right: 12px;
        margin-top: 12px;
        padding: 16px;
        cursor: pointer;
        user-select: none;
        .bg-job-title {
          color: #DDDDDD;
        }
        .bg-job-status {
          color: #DDDDDD;
          opacity: 0.7;
        }
      }
    }
    .fm-title-2 {
      margin-top: 30px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.001em;
      color: #DDDDDD;
      padding-bottom: 17px;
      border-bottom: 1px solid #2D3645;
    }
    .fm-function-library {
      width: 100%;
      height: 482px;
      overflow-x: hidden;
      overflow-y: scroll;
      .fm-flow {
        pointer-events: initial;
        cursor: pointer;
        border-radius: 8px;
        width: 50%;
        display: inline-block;
        margin-top: 18px;
        padding-left: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        > img {
          height: 29.1px;
          margin-left: 5px;
        }
        .fm-flow-right {
          display: inline-block;
          vertical-align: middle;
          margin-left: 10.8px;
          .fm-flow-title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #55B9FC;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 90%;
          }
          .fm-flow-meta {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #DDDDDD;
            margin-top: 2px;
          }
        }
      }
    }
    .fm-close-button {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 15px;
      opacity: 0.7;
      &:hover {
        opacity: 1.0;
      }
    }
    .fm-pane-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.0015em;
      color: #DDDDDD;
      margin-bottom: 14px;
    }
    .fm-pane-left {
      width: 186px;
      padding-right: 20px;
      border-right: 1px solid #252D3A;
      height: 100%;
      display: inline-block;
      vertical-align: top;
    }
    .fm-pane-right {
      width: calc(100% - 188px);
      padding-left: 20px;
      height: 100%;
      overflow-y: scroll;
      display: inline-block;
      vertical-align: top;
    }
  }
  &.fm-dragging {
    opacity: 0.2;
    pointer-events: none;
    .flow-modal {
      transition: 0.01s;
      transform: translateX(-9999px);
    }
  }
}

.fm-control-type-title {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2054em;
  color: #DDDDDD;
}
.fm-draggable-control + .fm-control-type-title {
  margin-top: 32px;
}

.fm-file-upload {
  position: absolute;
  top: 110px;
  right: 10px;
  width: 300px;
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.001em;
    color: #DDDDDD;
    padding-bottom: 1px; 
  }
  input {
    color: #DDDDDD;
  }
  .fm-file-uploading {
    margin-top: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.001em;
    color: #DDDDDD;
    padding-bottom: 1px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow-x: hidden;
  }
  .fm-file-upload-error {
    margin-top: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.001em;
    color: #DD2222;
    padding-bottom: 1px; 
  }
  .fm-file-upload-complete {
    margin-top: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.001em;
    color: #22DD22;
    padding-bottom: 1px; 
  }
}

.fm-uppb-cont {
  margin-top: 16px;
  width: 100%;
  height: 22px;
  padding: 2px;
  background-color: #000000;
  border-radius: 8px;
  .fm-uppb-bar {
    min-width: 20px;
    text-align: center;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 8px;
    color: #fff;
    line-height: 18px;
  }
}

.start-job-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1 !important;
}
.check-job-button {
  margin-top: 8px;
}
.delete-dataset-button {
  margin-top: 8px;
  background-color: red !important;
  margin-left: 140px;
}
.manage-datasets-modal {
  .stream-upload-cont {
    margin-left: 16px;
    margin-top: 16px;
    .fm-title {
      margin-bottom: -10px;
    }
  }
}
.check-job-info {
  font-style: normal;
  font-size: 12px;
  letter-spacing: 0.2054em;
  color: white;
  .value {
    font-weight: bold;
  }
  width: 100%;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 50px;
}
.start-all-jobs-button {
  position: absolute;
  bottom: 20px;
  right: 110px;
}

.fm-draggable-control {
  width: 182px;
  height: 131px;
  background: #2D3645;
  border: 1px solid #404C5F;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: inline-block;
  vertical-align: top;
  margin-right: 12px;
  margin-top: 12px;
  padding: 16px;
  cursor: move;
  user-select: none;
  .fm-control-title {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.0125em;
    color: #DDDDDD;
    margin-bottom: 7px;
  }
  .fm-control-description {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.0004em;
    color: #FFFFFF;
    opacity: 0.7;
  }
  .fm-control-inputs {
    margin-top: 7px;
  }
  .fm-control-inputs, .fm-control-outputs {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 140%;
    letter-spacing: 0.0004em;   
    color: #FFFFFF;
  }
}

.react-flow__minimap {
  background: #252D3A;
  border: 3px solid #2D3645;
  .react-flow__minimap-mask {
    fill: #2D3645;
  }
  .react-flow__minimap-node {
    fill: #2D3645;
    stroke: #2D3645;
  }
}

.flow-radio-select {
  pointer-events: initial;
  .fcs-title {
    margin-bottom: -8px !important;
  }
  .fr-option {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.0004em;
    color: #DDDDDD;
    margin-right: 24px;
    user-select: none;
    cursor: pointer;
    .fro-circle {
      position: relative;
      top: 3px;
      width: 16px;
      height: 16px;
      border-radius: 16px;
      background: #2D3645;
      border: 3px solid #2D3645;
      display: inline-block;
      margin-right: 9px;
    }
    &.fro-checked {
      color: #55B9FC;
      .fro-circle {
        background: #55B9FC;
      }
    }
  }
}

.flow-color-preview {
  .fcs-title {

  }
  .fcs-pal-cont {
    pointer-events: initial;
    width: 100%;
    height: 32px;
    border-radius: 6px;
    overflow: hidden;
    .fcs-pal-clr {
      vertical-align: top;
      height: 32px;
      display: inline-block;
    }
  }
  margin-bottom: 8px;
}

.flow-color-select {
  margin-top: -16px;
  margin-bottom: 16px;
  pointer-events: initial;
  ::-webkit-color-swatch-wrapper {
    padding: 0; 
  }
  ::-webkit-color-swatch {
    border: none;
    border-radius: 6px;
    background: #2D3645;
  }
  .fcs-title {
    display: inline-block;
    margin-right: 16px;
  }
  input {
    background: #2D3645;
    margin-right: 24x;
    border: none;
    width: 48px;
    height: 28px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    border-radius: 6px;
    position: relative;
    top: 8px;
  }
}

.flow-node-wrapper {
  .flow-node-scrolling-cont {
    pointer-events: initial;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    .flow-trd-item {
      position: relative;
      background: #1C222D;
      border: 1px solid #343E50;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      height: 112px;
      margin-bottom: 12px;
      padding: 12px;
      pointer-events: initial;
      .fcs-title {
        pointer-events: none;
      }
      .flow-trdi-title {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.0125em;
        color: #DDDDDD;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
        width: calc(100% - 100px);
        margin-bottom: 11px;
      }
      .flow-checkkbox-wrapper {
        position: absolute;
        right: 12px;
        top: -10px;
      }
    }
  }
  .fcs-cs-or-val {
    display: inline-block;
    width: calc(50% - 8px);
    margin-right: 8px;
    .flow-checkkbox-wrapper {
      float: right;
      margin-top: -100px;
    }
  }
  .fcs-title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.0004em;
    color: #DDDDDD;
    margin-bottom: 7px;
  }
  .fcs-tooltip-properties {
    pointer-events: initial;
    max-height: 146px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: -15px;
    .fcs-tooltip-property {
      height: 61px;
      margin-bottom: 12px;
      background: #1C222D;
      border: 1px solid #343E50;
      box-sizing: border-box;
      border-radius: 5px;
      > div {
        margin-top: 10px;
        display: inline-block;
        width: 95%;
      }
      > img {
        margin-top: -16px;
        margin-left: -10px;
      }
      .fcs-title {
        display: inline-block;
        width: 33%;
        margin-left: 3%;
        line-height: 40px;
        height: 50px;
        vertical-align: top;
      }
      .en-dropdown-button {
        width: 55%;
      }
      .fcs-delete-property {
        cursor: pointer;
      }
    }
  }
  .fcs-enum-values {
    pointer-events: initial;
    max-height: 300px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: -15px;
    .fcs-enum-value {
      min-height: 61px;
      margin-bottom: 12px;
      background: #1C222D;
      border: 1px solid #343E50;
      box-sizing: border-box;
      border-radius: 5px;
      > div {
        margin-top: 10px;
        display: inline-block;
        width: 95%;
      }
      > img {
        margin-top: -20px;
        margin-left: 10px;
      }
      .fcs-title {
        display: inline-block;
        width: 33%;
        margin-left: 3%;
        line-height: 40px;
        height: 50px;
        vertical-align: top;
      }
      .en-input-wrapper {
        width: 55%;
      }
      .fcs-delete-property {
        cursor: pointer;
      }
      .fcs-add-title {
        width: calc(100% - 30px);
        margin-left: 20px;
        margin-bottom: -20px;
        top: -20px;
        position: relative;
      }
    }
    .fcs-enum-input-val {
      display: block !important;
      width: calc(100% - 90px);
      margin-left: 40px;
      height: 30px !important;
      position: relative;
      top: -20px;
      margin-bottom: 5px;
      .fcs-title {
        width: 40px;
      }
      .en-input-wrapper {
        width: calc(100% - 105px);
        margin-top: -20px;
      }
      .fcs-delete-property {
        margin-left: 10px;
        margin-bottom: 20px; 
      }
    }
  }
  .fcs-io-title {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2054em;
    color: #DDDDDD;
    margin-top: 16px;
    margin-bottom: 32px;
    border-bottom: 1px solid #2D3645;
    height: 1px;
    line-height: 1px;
    text-shadow: 0px 0px 4px #252D3A,0px 0px 4px #252D3A,0px 0px 4px #252D3A,0px 0px 4px #252D3A,0px 0px 4px #252D3A;
  }
  .fcs-add-title {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2054em;
    color: #DDDDDD;
    padding-bottom: 8px;
    margin-top: 16px;
    margin-bottom: 32px;
    border-bottom: 1px solid #2D3645;
    .fcs-add-button {
      pointer-events: initial;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      letter-spacing: 0.001em;
      color: #55B9FC;
      float: right;
      margin-top: -1px;
      margin-right: 4px;
      cursor: pointer;
    }
  }
  .fcs-half {
    display: inline-block;
    width: calc(50% - 8px);
    margin-right: 8px;
  }
  .fcs-third {
    display: inline-block;
    width: calc(40% - 12px);
    margin-right: 12px;
    vertical-align: top;
    pointer-events: initial;
  }
  .fcs-two-thirds {
    display: inline-block;
    width: calc(60% - 8px);
    margin-left: 8px;
    vertical-align: top;
    pointer-events: initial;
  }
  .fcs-sep {
    width: 100%;
    border-bottom: 1px solid #2D3645;
    height: 1px;
    margin-top: 16px;
    margin-bottom: 16px;
    color: #DDDDDD;
    text-align: center;
    line-height: 0px;
    margin-bottom: 24px;
    text-shadow: 0px 0px 4px #252D3A,0px 0px 4px #252D3A,0px 0px 4px #252D3A,0px 0px 4px #252D3A,0px 0px 4px #252D3A;
  }
  width: 313px;
  height: 56px;
  background: #252D3A;
  border: 1px solid #343E50;
  box-sizing: border-box;
  border-radius: 6px;
  line-height: 56px;
  position: relative;

  .en-input-wrapper {
    background-color: #2D3645;
    border-radius: 6px;
  }
  .en-dropdown-button {
    pointer-events: initial;
    margin: 0px;
    padding: 0px;
    max-width: 100%;
    width: 100%;
    background-color: #2D3645;
    border-radius: 6px;
    line-height: 39px;
    text-indent: 16px;
    .en-dd-bubble {
      margin-top: 0px;
      z-index: 10;
    }
  }
  .en-dropdown-button.en-typesearch-wrapper {
    text-indent: 3px;
  }
  .en-dropdown-filter-input, .en-input-wrapper {
    pointer-events: initial;
    max-width: 100%;
    width: 100%;
  }
  .en-dd-bubble {
    z-index: 15;
  }
  .en-dd-option {
    text-indent: 0px;
  }
  .flow-drag-icon {
    height: 12px;
    margin-left: 17px;
    margin-right: 12px;
    margin-top: -2px;
    cursor: move !important;
    pointer-events: none;
  }
  .flow-edit-icon, .flow-delete-icon {
    pointer-events: initial;
    float: right;
    margin-top: 20px;
    cursor: pointer;
  }
  .flow-edit-save-icon {
    pointer-events: initial;
    position: absolute;
    opacity: 0.7;
    cursor: pointer !important;
    &:hover {
      opacity: 1.0;
    }
    top: 18px;
    right: 64px;
    z-index: 1;
    height: 20px;
  }
  .flow-edit-cancel-icon {
    pointer-events: initial;
    position: absolute;
    opacity: 0.7;
    cursor: pointer !important;
    &:hover {
      opacity: 1.0;
    }
    top: 21px;
    right: 32px;
    z-index: 1;
    height: 14px;
  }
  .flow-edit-icon {
    margin-right: 20px;
    opacity: 0.7;
    cursor: pointer !important;
    &:hover {
      opacity: 1.0;
    }
  }
  .flow-delete-icon {
    margin-right: 40px;
    opacity: 0.7;
    cursor: pointer !important;
    &:hover {
      opacity: 1.0;
    }
  }
  .flow-node-js-editor-wrapper {
    pointer-events: initial;
  }
  .flow-edit-maximize, .flow-edit-minimize {
    border: 1px solid #343E50;
    box-sizing: border-box;
    border-radius: 8px;
    height: 26px;
    text-align: center;
    padding: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    letter-spacing: 0.0004em;
    color: #55B9FC;
    width: 80px;
    position: absolute;
    right: 96px;
    top: 16px;
    cursor: pointer;
    pointer-events: initial;
    &:hover {
      border: 1px solid #55B9FC;
    }
  }
  .flow-node-name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.0015em;
    color: #FFFFFF;
    display: inline-block;
    max-width: calc(100% - 140px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    top: 4.5px;
  }
  .fn-right-side {
    background: #386994;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 0px 6px 6px 0px;
    height: calc(100% + 2px);
    width: 15px;
    z-index: 0;
    position: absolute;
    right: -1px;
    top: -1px;
  }
  &.fnw-editing {
    height: auto;
    padding: 16px;
    padding-right: 31px;
    pointer-events: none;
     .flow-node-title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.0015em;
      color: #FFFFFF;
      margin-bottom: 12px;
      display: block;
    }
    .flow-node-name {
      display: block;
      margin-bottom: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.0004em;
      color: #DDDDDD;
    }
    .en-input-wrapper {
      pointer-events: initial;
      input {
        max-width: calc(100% - 25px);
        top: -10px;
        position: relative;
      }
    }
  }
}

.react-flow__node.selected {
  visibility: hidden;
  .flow-node-wrapper {
    visibility: visible;
    border: 1px solid #55B9FC;
    .fn-right-side {
      border: 1px solid #55B9FC;
      border-left: 1px solid transparent;
    }
  }
}

.react-flow__controls {
  border: 1px solid #343E50;
  border-radius: 10px;
  background: #252D3A;
  button {
    background: #252D3A;
    box-sizing: border-box;
    border: none;
    width: 22px;
    height: 28px;
    border-radius: 10px;
    margin-left: 2px;
    margin-right: 2px;
    &.react-flow__controls-interactive {
      display: none;
    }
    svg {
      path {
        fill: #DDDDDD;
      }
    }
    &:focus {
      outline: none;
      border: none;
    }
    &:hover {
      background-color: transparent;
      svg {
        path {
          fill: #55B9FC !important;
        }
      }
    }
  }
}

.flow-button-og {
  background: #55B9FC;
  border-radius: 10px;
  height: 32px;
  position: absolute;
  z-index: 9;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.001em;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 8px;
  color: #FFFFFF;
  img {
    height: 9.33px;
    margin-top: -3px;
    margin-right: 2px;
  }
  &.add-block {
    left: 14px;
    top: 26px;
  }
  &:hover {
    background-color: #80ccff;
  }
}

.flow-wrapper {
  margin-left: -10px;
  width: calc(100% + 20px);
  padding: 0px;
  margin-top: -25px;
  .flow-switcher-wrapper {
    width: 192px;
    border-right: 2px solid #252D3A;
    height: 76px;
    display: inline-block;
    vertical-align: top;
  }
  .flow-header-right {
    display: inline-block;
    width: calc(100% - 200px);
    height: 70px;
    margin-top: 2px;
    vertical-align: top;
    .flow-pname-label {
      color: #DDDDDD;
      display: inline-block;
      position: relative;
      margin-top: 18px;
      margin-left: 20px;
    }
    .flow-run-button {
      float: none !important;
      /*margin-left: 40px !important;*/
      &.cancel-flow-button {
        pointer-events: initial !important;
        color: #FF0000;
        border-color: #FF0000;
        font-size: 15px;
        font-weight: bold;
        z-index: 25 !important;
        .pc-bar {
          display: none;
        }
      }
    }
    .en-input-wrapper {
      width: 300px;
      input {
        max-width: 280px;
      }
      margin-left: 10px;
      display: inline-block;
      position: relative;
      margin-top: 18px;
    }
    .flow-button {
      float: right;
      margin-top: 18px;
      margin-right: -10px;
      margin-left: 23px;
    }
  }
  .percent-complete {
    position: fixed;
    width: 200px;
    height: 40px;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -20px;
    text-align: center;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.75);
    border: 1px solid #FFF;
    .pc-percent {
      color: #fff;
      font-size: 20px;
      z-index: 2;
      line-height: 40px;
    }
    .pc-bar {
      display: block;
      position: relative;
      background-color: #0F0;
      opacity: 0.5;
      left: 0px;
      z-index: 1;
      height: 40px;
      top: -40px;
    }
  }
  height: calc(100vh - 160px);

  &.loading {
    &::after {
      content: '';
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 20;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.25);
      cursor: wait !important;
      pointer-events: initial !important;
    }
    pointer-events: none;
    * {
      pointer-events: none !important;
    }
  }

  .flow-list {
    position: absolute;
    right: 3em;
    background: #172234;
    z-index: 5;
    padding: 6px;
    margin-top: -1.2em;
    border-radius: 8px;
    .flow-list-close {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
    max-height: 300px;
    overflow-y: scroll;
    .flow-list-item {
      color: #ddd;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
      padding: 6px;
      padding-right: 25px;
      &:hover {
        color: #fff;
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  .flow-grid-wrapper {
    position: relative;
  }
  .react-flow {
    height: 100%;
    border: 2px solid #252D3A;
  }
  &.view-data {
    .react-flow {
      //height: calc(100vh - 524px) !important;
    }
  }
  .view-data-map {
    width: 100%;
    height: 100%;
  }
  .fcs-col-scroll {
    /*width: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    max-height: 300px;
    overflow-y: scroll;
    padding: 10px;*/
  }
  .view-data-json {
    width: calc(100% - 20px);
    vertical-align: top;
    height: 100%;
    overflow: scroll;
    display: inline-block;
    margin-right: 12px;
    margin-left: 20px;
  }
  .view-data-json-2, .view-data-table-2 {
    margin-left: 12px;
    vertical-align: top;
    width: 460px;
    &.view-data-table-2 {
      width: 360px;
    }
    height: 300px;
    display: inline-block;
    border-left: 2px solid rgba(0, 0, 0, 0.3);
    padding-left: 12px;
  }
  .view-data-table {
    width: 100%;
    vertical-align: top;
    display: inline-block;
    height: 100%;
    overflow: scroll;
    white-space: nowrap;
    .vdt-scroll {
      width: auto;
      height: 100%;
      .vdt-header {
        height: 35px;
        .vdt-th {
          background-color: #12161d;
          color: #FFFFFF;
          display: inline-block;
          width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 6px;
        }
      }
      .vdt-tr {
        height: 35px;
        .vdt-val {
          background-color: #252D3A;
          color: #DDDDDD;
          display: inline-block;
          width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 6px;
        }
      }
    }
  }
  .react-flow__node {
    &.selected {
      border: 2px solid #FFFFFF !important;
      border-radius: 10px;
      margin-top: -2px;
      margin-left: -2px;
    }
  }
  .react-flow__edge {
    opacity: 0.65;
    &.selected {
      opacity: 1.0;
    }
  }
}

.bgj-container {
  display: block;
  height: calc(100vh - 200px);
  width: calc(100vw - 135px);
  &.bgj-loading {
    opacity: 0.5;
    pointer-events: none;
  }
  .bgj-create {
    display: inline-block;
    margin-left: 20px;
    margin-top: 22px;
  }
  .bgj-stream {
    display: inline-block;
    margin-left: 20px;
    margin-top: 22px;
  }
  .bgj-red-button {
    background: #FC5555;
    border-radius: 10px;
    height: 32px;
    z-index: 9;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.001em;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 8px;
    color: #FFFFFF;
    float: right;
    margin-right: 10px;
  }
  .bgj-title {
    width: 212px;
    border-right: 2px solid #252D3A;
    height: 76px;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    color: #DDDDDD;
    margin-left: -10px;
    margin-top: -25px;
    line-height: 70px;
    border-bottom: 2px solid #252D3A;
  }
  .bgj-header-right {
    display: inline-block;
    width: calc(100% - 210px);
    height: 76px;
    margin-top: 2px;
    vertical-align: top;
    border-bottom: 2px solid #252D3A;
    margin-top: -25px;
    .flow-pname-label {
      color: #DDDDDD;
      display: inline-block;
      position: relative;
      margin-top: 18px;
      margin-left: 20px;
    }
    .flow-run-button {
      float: none !important;
      /*margin-left: 40px !important;*/
      &.cancel-flow-button {
        pointer-events: initial !important;
        color: #FF0000;
        border-color: #FF0000;
        font-size: 15px;
        font-weight: bold;
        z-index: 25 !important;
        .pc-bar {
          display: none;
        }
      }
    }
  }
  .bg-pane-1 {
    vertical-align: top;
    display: inline-block;
    width: 200px;
    margin-right: 10px;
    height: 100%;
    overflow-y: scroll;
    h2 {
      color: white;
      margin-left: 10px;
      margin-top: 20px;
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  .bg-pane-2o-wrapper {
    vertical-align: top;
    display: inline-block;
    width: calc(100% - 208px);
    margin-left: -10px;
    .bg-pane-2 {
      border-left: 2px solid #252D3A;
      border-right: 2px solid #252D3A;
      width: 100%;
      height: 82px;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      .bg-new-job {
        margin-top: 10px;
        margin-left: 10px;
        color: white;
        h2 {
          color: white;
          font-size: 17px;
          margin-bottom: 20px;
        }
        .bg-nj-header {
          
        }
      }
      .bg-job-inline {
        display: inline-block;
        vertical-align: top;
        margin-right: 20px;
      }
      .bg-job-display {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        h2 {
          display: inline-block;
          width: auto;
          color: #DDDDDD;
          margin-left: 50px;
          vertical-align: top;
          margin-right: 20px;
          line-height: 0px;
        }
        .bg-js-file {
          vertical-align: top;
          display: inline-block;
        }
      }
    }
    .bg-output-pane {
      width: 100%;
      height: calc(100vh - 286px);
      padding: 0px;
      color: white;
      border: 2px solid #252D3A;
      border-bottom: none;
      .view-data-map {
        width: 100%;
        height: 100%;
      }
      .view-data-json {
        width: 100%;
        height: 100%;
        margin-left: 20px;
        vertical-align: top;
        overflow: scroll;
        display: inline-block;
        margin-right: 12px;
      }
      .view-data-table {
        width: 100%;
        vertical-align: top;
        display: inline-block;
        height: 100%;
        overflow: scroll;
        white-space: nowrap;
        .vdt-scroll {
          width: auto;
          height: 100%;
          .vdt-header {
            height: 35px;
            .vdt-th {
              background-color: #12161d;
              color: #FFFFFF;
              display: inline-block;
              width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 6px;
            }
          }
          .vdt-tr {
            height: 35px;
            .vdt-val {
              background-color: #252D3A;
              color: #DDDDDD;
              display: inline-block;
              width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 6px;
            }
          }
        }
      }
    }
  }
  .bg-job-block-0, .bg-job-block-1, .bg-job-block-2, .bg-job-block-3 {
    width: 177px;
    height: 151px;
    background: #2D3645;
    border: 1px solid #404C5F;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: inline-block;
    vertical-align: top;
    margin-right: 12px;
    margin-top: 12px;
    padding: 16px;
    cursor: pointer;
    user-select: none;
    .bg-job-title {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 14px;
      letter-spacing: 0.0125em;
      color: #DDDDDD;
      margin-bottom: 7px;
    }
    .bg-job-progress {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 14px;
      letter-spacing: 0.0125em;
      color: #DDDDDD;
      margin-bottom: 7px;
    }
    .bg-job-status {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.0004em;
      color: #FFFFFF;
      opacity: 0.7;
    }
    .fm-control-inputs {
      margin-top: 7px;
    }
    .fm-control-inputs, .fm-control-outputs {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 140%;
      letter-spacing: 0.0004em;   
      color: #FFFFFF;
    }
    &.bg-job-selected {
      border: 2px solid #55B9FC;
    }
    .bg-job-title {
      font-weight: bold;
    }
    .bg-job-progress {
      opacity: 0.8;
    }
    .bg-job-status {
      opacity: 0.8;
    }
  }
  .bg-js-file {
    .bg-job-title {
      font-weight: bold;
    }
    .bg-job-progress {
      opacity: 0.8;
    }
    .bg-job-status {
      opacity: 0.8;
    }
  }
  .bg-selected-job {
    padding: 10px;
    color: #DDDDDD;
    display: block;
    .bg-job-title {
      font-weight: bold;
    }
    .bg-job-progress {
      opacity: 0.8;
    }
    .bg-job-status {
      opacity: 0.8;
    }
    h2 {
      margin-top: 20px;
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
}

.flow-output-cont, .bg-output-pane {
  margin-top: 0px;
  .flow-output-resize-handle {
    width: 100%;
    height: 3px;
    background-color: #252D3A;
    cursor: ns-resize;
  }
  border-left: 2px solid #252D3A;
  border-right: 2px solid #252D3A;
  border-bottom: 2px solid #252D3A;
  .flow-output-header {
    background: #1F2631;
    width: 100%;
    height: 38px;
    border-bottom: 2px solid #252D3A;
    .flow-output-vd-title {
      display: inline-block;
      cursor: pointer;
      color: #DDDDDD;
      margin-left: 20px;
      border-left: 2px solid #252D3A;
      height: 42px;
      top: -4px;
      position: relative;
      padding-left: 12px;
      padding-top: 12px;
      vertical-align: top;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 150px;
      user-select: none;
      &:hover {
        color: #FFF;
      }
      &.flow-output-selected {
        color: #55B9FC;
        cursor: initial;
        &:hover {
          color: #55B9FC;
        }
      } 
    }
    .flow-output-title {
      vertical-align: top;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2054em;
      color: #DDDDDD;
      display: inline-block;
      margin-top: 10px;
      margin-left: 20px;
    }
    .flow-output-export-button {
      float: right;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      letter-spacing: 0.001em;
      color: #55B9FC;
      cursor: pointer;
      margin-right: 20px;
      margin-top: 11px;
      img {
        height: 13.3px;
        margin-right: 3px;
        margin-top: -3px;
      }
    }
  }
  .flow-output-body {
    width: 100%;
    height: calc(100% - 41px);
    .flow-map-output {
      margin-top: 0px;
      width: 100%;
      height: 100%;
      .map-container {
        width: 100%;
        height: 100%;
      }
    }
  }
  &.bg-output-pane   {
    .flow-output-header {
      .flow-output-vd-title {
        top: 0px !important;
      }
      height: 44px;
    }
    .flow-output-body {
      height: calc(100% - 41px);
    }
    .flow-output-title {
      line-height: 24px;
    }
  }
}

.insights-map-page-cont {
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #DDDDDD;
    margin-bottom: 16px;
  }
  .flow-map-output {
    border: 2px solid #252D3A;
    margin-top: 0px;
    width: 100%;
    height: 100%;
    .map-container {
      width: 100%;
      height: 100%;
    }
  }
}