.unresponsive-bubble-top {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    b {
      font-weight: 500;
      color: #F45959;
    }
  }

.unresponsive-bubble-bottom {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #B3C9D9;
    b {
      font-weight: 500;
      color: #FFFFFF;
    }
}