.simp-pie-cont {
  position: relative;
  padding-bottom: 32px;
  text-align: center;

  .sp-canvas-cont {
    display: inline-block;
    position: relative;
  }

  .total-cont {
    position: absolute;
    width: 100px;
    height: 186px;
    left: 50%;
    top: 50%;
    margin-left: -50px;
    margin-top: -88px;
    text-align: center;
    &.tc-extra-height {
      margin-top: -99px;
    }
    .tc-tog {
      cursor: pointer;
      margin-top: 15px;
      margin-bottom: 15px;
      i {
        color: #AAA;
        font-size: 28px;

        &:hover {
          color: white;
        }
      }
    }
    .tc-total-delta {
      color: #4aac6b;
      font-weight: bold;
      &.tc-loss {
        color: #c05c62;
      }
      width: 150px;
      margin-left: -25px;
    }
    .tc-total {
      color: white;
      font-size: 25px;
      font-weight: bold;
    }
    .tc-per-year {
      color: #AAA;
      font-size: 14px;
    }
  }

  .sh-delta-percent {
    color: #4aac6b;
    position: relative;
    left: 18px;
    font-weight: bold;
    &.sh-loss {
      color: #c05c62;
    }
  }

  .sh-label-outside {
    display: inline-block !important;
    float: none !important;
    margin-top: 16px;
    margin-left: 16px;

    .sh-label-cont {
      width: 200px;
      height: 60px;
      text-align: left;

      .sh-box {
        width: 22px;
        height: 22px;
        border-radius: 2px;
        display: inline-block;
        float: left;
        margin-top: 2px;
      }

      .sh-label {
        width: 163px;
        display: inline-block;
        float: right;
        .sh-top-label {
          color: white;
          font-size: 15px;
          font-weight: bold;
        }

        .sh-bot-label {
          color: #AAA;
          font-size: 15px;
        }
      }

      &.space-heating-label {
        .sh-box {
          background-color: #4fbd6f;
        }
      }
      &.space-cooling-label {
        .sh-box {
          background-color: #44d9e6;
        }
      }
      &.lights-label {
        .sh-box {
          background-color: #1a67c7;
        }
      }
      &.water-label {
        .sh-box {
          background-color: #d65229;
        }
      }
    }
  }

  .sp-no-bill-msg {
    margin-top: 1em;
    font-size: 16px;
    color: #dc6b4a;
  }
  .sp-adjusted-msg {
    margin-top: 1em;
    font-size: 16px;
    color: #4adc6b;
  }
}

@media (max-width: 1879px) and (min-width: 1199px) {
  .simp-pie-cont {
    text-align: center;
    canvas {
      float: none;
    }
    .total-cont {
      left: 50%;
      margin-left: -50px;
    }
    .sh-label-outside {
      margin-top: 32px;
      display: inline-block;
      float: none;
    }
  }
}

@media (max-width: 1198px) {
  .simp-pie-cont {
    canvas {
      float: none;
      margin-left: 0px;
      padding-right: 5%;
      position: relative;
    }
    .total-cont {
      margin-left: -55px;
      position: absolute;
    }
    .sh-label-outside {
      float: none;
    }
  }
}