.new-sidebar {
  position: absolute;
  left: -73px;
  top: 0px;
  width: 73px;
  min-height: 100%;
  background: #1c222d;
  z-index: 1;
  .burger {
    height: 72px;
    background: #252D3A;
    position: relative;
    cursor: pointer;
    img {
      position: relative;
      top: 25px;
      left: 24px;
    }
  }
  .sidebar-item {
    cursor: pointer;
    height: 72px;
    &.selected {
      cursor: initial;
    }
    img {
      position: relative;
      top: 25px;
      left: 24px;
      width: 18px;
      height: auto;
    }
  }
}
