.enusermanagement-container {
  width: 100%;
  display: block;

  .page-title-white {
    display: inline-block;
    position: relative;
  	font-style: normal;
  	font-weight: 500;
  	font-size: 20px;
  	line-height: 23px;
  	letter-spacing: 0.0015em;
  	color: #FFFFFF;
  	margin-bottom: 30px;
    .cust-count {
      position: relative;
      display: inline-block;
      margin-left: 10px;
      padding-left: 9px;
      padding-right: 9px;
      padding-top: 1px;
      padding-bottom: 1px;
      background: #252D3A;
      border-radius: 18px;
      top: 2px;
    }
  }

  > .en-button-primary {
    display: inline-block;
    margin-left: 16px;
  }

  .en-um-show-customers {
    display: inline-block;
    position: relative;
    top: -4.25em;
    left: 175px;
  }

  .en-um-by-org {
    .en-um-user-list {
      display: inline-block;
      vertical-align: top;
      max-width: calc(100% - 350px);
    }
  }
}
