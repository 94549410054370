.building-details-card {
  display: inline-block;
  background: #252D3A;
  border-radius: 24px;
  padding: 20px;
  padding-right: 0px;
  width: 322px;
  height: auto;
  margin-right: 0px;
  margin-bottom: 30px;

  .bc-sep {
    opacity: 0.12;
    width: 257px;
    height: 1px;
    background-color: #7A828D;
    margin-top: 26px;
    margin-bottom: 19px;
  }

  .bd-login-b360 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0125em;
    text-decoration-line: underline;
    color: #55B9FC;
    cursor: pointer;
    &:hover {
      color: #DDDDDD;
    }
    display: inline-block;
    position: relative;
    float: right;
    right: 18px;
    top: -23px;
  }

  .bd-name {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 145%;
    letter-spacing: 0.0015em;
    color: #FFFFFF;
    margin-top: 14px;
    margin-bottom: 13px;
  }

  .bd-actions {
    margin-top: 20px;
    margin-bottom: 20px;
    .en-button-primary {
      margin-right: 10px;
    }
    .en-button-link {
      padding-right: 0px;
    }
  }

  .bd-details-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #DDDDDD;
    margin-bottom: 5px;
    margin-top: 42px;
  }

  .bd-detail-half {
    display: inline-block;
    width: 50%;
    margin-top: 12px;
    .bdd-title {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.0025em;
      color: #7A828D;
      margin-bottom: 4px;
    }
    .bdd-value {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17.6px;
      letter-spacing: 0.0025em;
      color: #FFFFFF;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .bd-goals-objectives {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #7A828D;
    margin-top: 20px;
  }
  .bd-goals-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0025em;
    color: #FFFFFF;
    margin-top: 7px;
    width: 100%;
    white-space: pre-line;
  }

  .bd-pc-title {
    margin-top: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #DDDDDD;
  }

  .completeness-slider-large {
    margin-top: 11px;
  }

  .bd-pc-message {
    width: calc(100% - 20px);
    margin-top: 11px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0025em;
    color: #FFFFFF;
  }

  .insight-stat-cont {
    margin-top: 20px;
  }

  .bd-actions-row {
    margin-top: 20px;
    .bd-share-button {
      padding: 10px;
      background: #55B9FC;
      border-radius: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.0125em;
      color: #FFFFFF;
    }
  }
}
