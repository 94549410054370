@import '../../styles/app';

.hideOverflow {
  overflow: hidden;
}
.selectCustomization {
  > div {
    background: $bg-custom-dark;
    border: 1px solid $bg-custom-dark;
  }
  .react-select__single-value {
    color: $content-color;
  }
  .react-select__option--is-focused {
    background: $subtle-blue;
  }
  .react-select__option--is-selected {
    background: rgba($subtle-blue,.6);
  }
} 
.flipCard {
  background-color: #233149;
  height: 375px;
  perspective: 5000px;
  margin-bottom: 40px;
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipCardInner {
  animation: flip 10s linear infinite;
}

.flipCardFront, .flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flipCardFront {
  background-color: transparent;
  color: black;
  transform: rotateY(0deg);
}

.flipCardBack {
  background-color: transparent;
  color: white;
  transform: rotateY(180deg);
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  50% { 
    transform: rotateY(0deg);
  }
  60% {
    transform: rotateY(180deg);
  }
  90% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.positionElementBottom {
  position: absolute;
  bottom: 0;
}

.positionDescriptionText {
  position: absolute;
  bottom: 10%;
}

.postitionGroupElements {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0px;
  padding: 0 30px 15px;
}

.slideWrap {
  min-height: 320px;
  height: 100%;
  position: relative;
}

.heading4 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.wideName {
  width: 180px;
}



.itemMinWidth {
  min-width: 175px;
}

@media(max-width: 1450px) {
  .mobileAdjustment {
    display: flex;
    flex-direction: column;
  }
}

@media(max-width: 1235px) {
  .slideWrap {
    p.smallSite {
      font-size: 1.3rem;
    }
  }
}

.borderTop {
  border-top: 1px solid $bg-custom-dark!important;
}