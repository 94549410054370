.ac-cont {
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 32px;
  .ac-header {
    cursor: pointer;
    height: 64px;
    color: #DDD;
    .ac-header-text {
      width: 100%;
      font-size: 18px;
      text-align: center;
      .rvc-error-dot {
        display: none;
      }
      &.ac-header-error {
        color: #E67478;
        .rvc-error-dot {
          display: inline-block;
        }
      }
    }
  }
  .accordion-toggle {
    i {
      font-size: 22px;
      color: #AAA;
    }
  }
}

.upgrade-model-error-msg {
  color: #E67478;
  position: relative;
  top: 6px;
}

.ac-field {
  width: 50%;
  label {
    color: #d0d0d0;
  }
}

.ac-field-list {
  margin-top: -16px;
  margin-bottom: 32px;
}

.mid-button {
  width: 100%;
}

.ups-error-dot {
  position: relative;
  top: -13px;
  right: -5px;
  width: 8px;
  height: 8px;
  background-color: #E67478;
  display: inline-block;
  border-radius: 4px;
}