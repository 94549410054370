.hs-upgrade-cont {
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    .ac-header {
        background-color: transparent;
        padding-top: 5px;
        height: 5px !important;
    }
    .ac-cont {
        width: 95% !important;
        border-bottom: none;
    }
    .hpd-input-row {
        width: calc(100% - 16px);
        margin-left: 16px;
    }
    margin-bottom: 20px;
    .panel-body {
        padding-left: 16px;
        padding-right: 16px;
    }
}