.ac-cont {
  position: relative;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 32px;
  .ac-header {
    cursor: pointer;
    height: 64px;
    color: #DDD;
    .ac-header-text {
      width: 100%;
      font-size: 18px;
      text-align: center;
    }
  }
  .accordion-toggle {
    i {
      font-size: 22px;
      color: #AAA;
    }
  }
  .accordion-checkbox {
    position: absolute;
    left: 16px;
    top: 0px;
  }
  .panel-disabled {
    opacity: 0.25;
    pointer-events: none;
  }
}

.ac-field {
  width: 50%;
  label {
    color: #d0d0d0;
  }
}

.post-hl-fg {
  label {
    color: #d0d0d0;
  }
  input {
    margin-bottom: 8px;
  }
  center {
    font-size: 16px;
    color: #d0d0d0;
    margin-bottom: -15px;
  }
}

.ac-field-list {
  margin-top: -16px;
  margin-bottom: 32px;
}


.final-button {
  background-color: #e67e22 !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.est-rec-summary {
  color: #DDD;
}