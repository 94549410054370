.by-org-row {
  display: block;
  background: #252D3A;
  border-radius: 5px;
  padding: 8px;
  padding-right: 0px;
  width: 100%;
  margin-right: 30px;
  margin-bottom: 2px;
  vertical-align: top;

  .by-org-cell {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.0004em;
    color: #DDDDDD;
    margin-top: 2px;
    margin-bottom: 4px;
    max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bo-actions {
    float: right;
    margin-top: -24px;
    > div + div {
      margin-left: 12px;
    }
    div {
      height: 24px;
      line-height: 4px;
    }
  }
}
