.building-upload-wrapper {
	.building-details-page-container {
		width: 100%;
		display: block;
		margin-top: 0px;
		.page-title-white {
			color: #fff;
		}
		.building-details-container {
			display: inline-block;
			vertical-align: top;
			margin-right: 32px;
			float: left;
		}
		.back-button {
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			letter-spacing: 0.05em;
			color: #ffffff;
			cursor: pointer;
			margin-bottom: 20px;
			img {
				margin-right: 6px;
			}
		}
		.insights-page {
			width: 100%;
			margin-left: 0px;
			vertical-align: top;
			.page-title-white {
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 23px;
				letter-spacing: 0.0015em;
				color: #ffffff;
				margin-bottom: 22px;
			}
		}
		.building-details-right-cont {
			margin-left: 0px;
			vertical-align: top;
			display: inline-block !important;
			width: calc(100% - 375px) !important;
		}
	}
}
