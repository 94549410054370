.wizard-header-cont {
    padding: 5px;
    display: flex;
    align-items: center;
    .wh-status-cont {
        width: 250px;
        display: inline-block;
        .wh-status-main {
            font-family: 'Muli';
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 19px;
            color: #FFFFFF;
        }
        .wh-status-sub {
            font-family: 'Muli';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 19px;
            color: #FFFFFF;
            margin-top: 8px;
        }
    }
    .wh-np-button {
        display: inline-block;
        width: 315px;
        height: 84px;
        background: #2E5264;
        border: 2px solid #71D6E3;
        box-shadow: 0px 6px 7px #171A20;
        border-radius: 6px;
        margin-left: 24px;
        position: relative;
        cursor: pointer;
        .wh-np-back {
            margin-top: -24px;
            margin-left: 25px;
        }
        .wh-np-next {
            position: absolute;
            right: 25px;
            margin-top: 22px;
        }
        .wh-np-s {
            margin-top: 24px;
            margin-left: 20px;
            display: inline-block;
            .wh-np-s-main {
                font-family: 'Muli';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
            }
            .wh-np-s-sub {
                font-family: 'Muli';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 19px;
                color: #71D6E3;               
            }
        }
        &:hover {
            opacity: 0.75;
        }
        &.disabled {
            opacity: 0.2;
            pointer-events: none;
        }
    }
    .wh-current {
        margin-left: 24px;
        display: inline-block;
        flex-grow: 1;
        height: 84px;
        background: #29364E;
        border-radius: 6px;
        line-height: 102px;
        .wh-status-icon {
            display: inline-block;
            margin-top: -22px;
            margin-left: 24px;
        }
        .wh-current-s {
            margin-top: 24px;
            margin-left: 20px;
            display: inline-block;
            /*.wh-current-s-main {
                font-family: 'Muli';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
                position: relative;
            }*/
            .wh-current-s-sub {
                font-family: 'Muli';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 19px;
                color: #71D6E3;
                top: -8px;
                position: relative;
            }
        }
        .wh-current-button {
            width: 164px;
            height: 45px;
            background: #71D6E3;
            border-radius: 5px;
            float: right;
            font-family: 'Muli';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 45px;
            text-align: center;
            color: #1A2536;
            cursor: pointer;
            &:hover {
                opacity: 0.75;
            }
            margin-top: 20px;
            margin-right: 25px;
        }
    }
    .wh-status-icon {
        height: 34px;
    }
    .wh-last-published {
        display: inline-block;
        border-left: 1px solid #A1B6C7;
        height: 57px;
        padding-left: 26px;
        margin-left: 20px;
        .whlp-title {
            margin-top: 5px;
            font-family: 'Muli';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #B3C9D9;
        }
        .whlp-value {
            font-family: 'Muli';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 19px;
            color: #FFFFFF;
        }
    }
    margin-bottom: 40px;
}