@import '../../styles/app';

:global {
  .react-bootstrap-table-page-btns-ul {
    .page-link {
      border: 1px solid $subtle-blue!important;
      background-color: transparent;
      &:hover {

      }
    }
  }
  .ct-loading {
    font-size: 20px;
    width: 100%;
    text-align: center;
  }
}

.bootstrapTable {
  border: none;

  :global .table {
    thead,
    tbody {
      th,
      td {
        border-right: none;
        border-left: none;
        color: #FFFFFF; // Explicitly set text color to white
      }
    }

    thead th {
      background: transparent;
    }

    tbody td {
      vertical-align: middle;
    }
  }
}

.image {
  width: 100px;
  border-radius: 50px;
}

.promoAlert {
  transition: 0.6s;
  transition-timing-function: ease;
  transform: translateX(-130vw);
}

.showAlert {
  transform: translateX(0);
}

// Ensure Poppins font is applied
body {
  font-family: 'Poppins', sans-serif !important;
}