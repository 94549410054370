@import '../../styles/app';

.echarts-for-react  {
  width: 100%;
}
  /*          Post User           */
.post-user {
  position: relative;

  @include clearfix();

  img {
    border: 3px solid white;
  }
}
.form-control::placeholder {
  color: rgba(255, 255, 255, 0.1) !important;
}

.hpd-wrap {
  width: calc(100%);
  margin-left: 0px;
  margin-top: 24px;
  .hp-details-cont {
    min-height: 40px;
    padding-bottom: 1px;
  }
  margin-bottom: 32px;
}

.card-header {
  background-color: lighten($widget-bg-color, 5.0) !important;
}

.card-body {
  border: 1px solid lighten($widget-bg-color, 5.0) !important;
  section {
    background-color: lighten($widget-bg-color, 5.0) !important;
    margin-top: 1.5em;
    margin-bottom: -0.5em;
  }
}

/*            Tags              */
.tags {
  padding-left: 0;
  list-style: none;

  @include clearfix();

  > li {
    float: left;

    > a {
      padding: 2px 8px;
      font-size: $font-size-mini;
      border-radius: 6px;
      border: 1px solid white;
      color: inherit;
      text-decoration: none;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      .fa {
        font-size: 8px;
        margin-right: 3px;
        opacity: 0.8;
      }
    }
  }

  > li + li > a {
    margin-left: 6px;
  }
}

.widget-top-overflow > img + .tags {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
}

/*           Weather             */
.widget-image .forecast {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

/*       Chat List Group         */
.widget-chat-list-group {
  padding-top: $spacer/2;

  .list-group-item {
    margin-left: $widget-padding-horizontal;
    margin-right: $widget-padding-horizontal;
    padding: 0;
    border: 0;
    display: flex;

    div {
      width: 100%;
    }

    &:nth-child(even) {
      margin-left: 75px;
    }

    &:hover {
      background: none;
    }

    & + .list-group-item {
      margin-top: 20px;
    }

    .thumb,
    .thumb-sm {
      float: left;
      margin-right: 15px;
    }

    .time {
      font-size: $font-size-sm;
      color: $text-muted;
    }

    .sender {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: $font-size-mini;
      font-weight: $font-weight-normal;
      color: theme-color('primary');
    }

    .body {
      font-size: $font-size-mini;
      margin-bottom: 0;
    }

    &.on-right {
      div {
        padding-right: 1rem;
      }

      .thumb,
      .thumb-sm {
        order: 1;
        margin-left: auto;
        margin-right: 0;
      }

      .time {
        float: left;
      }

      .sender {
        text-align: right;
      }
    }
  }
}

.raa-model-sync-message {
  position: absolute;
  border: 1px solid #1dcbda;
  background-color: #1B2638;
  color: #1dcbda;
  z-index: 2;
  text-align: left;
  padding: 16px;
  width: 600px;
  border-radius: 4px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.25);
  bottom: 0px;
  pointer-events: initial;
  img {
    margin-left: 16px;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 16px;
    height: 16px;
    z-index: 3;
    &:hover {
      opacity: 0.5;
    }
  }
}

.set-margin {
  margin-top: 100px;
}

.raa-main-container {
  > * {
    font-family: 'Muli';
  }
}

.raa-widget-pane {
  background-color: $widget-bg-color;
  width: 100%;
  padding: 28px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 16px 16px 16px 16px;
  min-height: 400px;

  .raa-widget-left, .raa-widget-middle {
    border-right: 2px solid rgba(0, 0, 0, 0.85);
  }

  .pt-offset-up {
    margin-top: 12px;
  }

  &.rwp-first {
    .row {
      .col-lg-4 + .col-lg-4 {
        border-left: 2px solid rgba(0, 0, 0, 0.75);
      }
    }
  }
}

@media (max-width: 1198px) {
  .raa-widget-left, .raa-widget-middle {
    border-right: 2px solid transparent !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.85) !important;
    padding-bottom: 4rem !important;
    margin-bottom: 1rem !important;
  }
}

.raa-audit-status {
  position: absolute;
  text-align: right;
  margin-bottom: -3rem;
  pointer-events: none;
  right: 3.5rem;
  &.raa-has-reset {
    margin-bottom: -5rem;
  }
  width: 600px;
  grid-template-columns: auto;
  display: grid;
  grid-auto-flow: column;
  grid-row-gap: 16px;
  grid-column-gap: 16px;

  .raa-as-item {
    display: inline-block;
    min-width: 120px;
    text-align: right;

    &.raa-as-reset {
      min-width: 240px;
      margin-right: 24px;
      position: relative;
      top: -32px;
      pointer-events: initial;
    }

    .raa-as-item-label {
      color: $gray-500;
    }
    .raa-as-item-value-status {
      color: $gray-200;
      font-size: 20px;
    }
    .raa-as-item-value-confidence {
      color: #B00000;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

@media (max-width: 1507px) {

  .raa-audit-status {
    position: relative !important;
    text-align: left !important;
    margin-bottom: 1rem !important;
    left: 0px;
    .raa-as-item {
      margin-right: 1em !important;
      text-align: left !important;
    }
  }

}

@media (max-width: 600px) {

  .raa-audit-status {
    grid-auto-flow: row;
  }

}


@media (max-width: 768px) {
  .raa-widget-pane {
    margin-bottom: 3rem;
  }
}
      
.raa-tab {
  display: inline-block;
  background-color: $widget-bg;
  color: $gray-300;
  cursor: pointer;
  text-align: center;
  margin-right: 2px;
  &.selected {
    background-color: $widget-bg-color;
    pointer-events: none;
  }
  font-size: 18px;
  width: 200px;
  padding: 16px;
  border-radius: 16px 16px 0px 0px;
}

@media (max-width: 1376px) {

  .raa-tab {
    font-size: 16px;
    width: 125px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 16px 16px 0px 0px;
  }

}

.btn-short {
  width: 100px !important;
}

.raa-user-edit {
  background-color: $widget-bg-color;
  width: 100%;
  padding: 28px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 16px;
  margin-top: -52px;
  margin-bottom: 56px;
}

.raa-user-info-header {
  background-color: $widget-bg-color;
  width: 100%;
  padding: 28px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 16px;
  margin-bottom: 56px;

  &.unresponsive {
    border: 1px solid #F45959;
  }

  .form-buttons {
    position: relative;
    button {
      width: 75px;
      float: right;
      right: 0.5em;
      margin-top: -3.25em;
      &.fb-dl-button {
        width: 180px;
        margin-right: 80px;
      }
    }
  }

  .raa-uih-field {
    display: inline-block;
    min-width: 260px;

    .raa-uih-field-label {
      color: $gray-500;
    }

    .raa-uih-field-data {
      color: $gray-200;
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 95%;
    }
  }
}

.pane-title {
  margin-top: 28px;
  margin-bottom: 28px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.third-button {
  width: 28%;
}

.mid-button, .final-button, .third-button {
  padding: 12px;
  &:hover {
    background-color: white !important;
  }
  &:disabled {
    pointer-events: none !important;
    opacity: 0.25 !important;
    cursor: not-allowed !important;
  }
}

.flabel-error {
  color: #e67478 !important;
}

.raa-user-report-cont-hd {
  background-color: white;
  position: relative;
  top: 5em;
  margin-bottom: 6em;
}

.reset-confirm-msg {
  color: #f67478;
  font-size: 15px;
  font-weight: bold;
  .mid-button {
    width: 48%;
    height: 35px;
    padding: 6px;
    float: left;
    margin-top: 18px;
  }
  .red-reset-button {
    width: 48%;
    height: 35px;
    float: right;
    margin-top: 18px;
  }
}

.red-reset-button {
  background-color: #e67478;
  border: 1px solid rgba(0, 0, 0, 0.25);
  z-index: 10;
  cursor: pointer;
  width: 100%;
  margin-top: 8px;
  &:hover {
    background-color: white !important;
  }
  &:disabled {
    pointer-events: none !important;
    opacity: 0.25 !important;
    cursor: not-allowed !important;
  }
}

.base-model-main-buttons { 
  width: 100%;

  margin-top: 2em;
  margin-left: 2em;

  .general-info, .hvac, .envelope {
    display: inline-block;
    border-radius: 4px;
    background: #233149;
    color: #FFFFFF;
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    border: none;
    width: 180px;
    height: 38px;
    margin-right: 10px;
    position: relative;
    img {
      height: 14px;
      margin-right: 8px;
      margin-top: -2px;
    }
    &.selected {
      background: #4BD37B;
      color: #29323A;
    }
  }

  .update-next {
    float: right;
    height: 38px;
    width: 280px;
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    margin-right: 3em;
  }

  margin-bottom: 5em;
}

.bmodel-tab-inner-header {
  margin-left: 2em;
  color: white;
  &.sub-header {
    margin-top: -3em;
    margin-bottom: 1em;
    color: rgba(244,244,245,0.6);
  }
  &.top-sep {
    margin-top: 2em;
    border-top: 2px solid #1B2638;
    padding-top: 3em;
  }
  button {
    margin-left: 16px;
    width: 120px;
    height: 26px;
    padding: 8px;
    line-height: 10px;
    margin-top: -3px;
    color: white;
  }
  img {
    margin-top: -2px;
    margin-right: 8px;
  }
}

.bmodel-tab-inner-fields {
  margin-left: 2em;
  margin-top: 2em;
  label {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: rgba(244,244,245,0.6);
    .bm-sqft {
      display: inline-block;

      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;

      letter-spacing: -0.07em;

      color: rgba(244, 244, 245, 0.6);
      margin-left: 5px;

      opacity: 0.3;
    }
  }
  .form-group.row {
    width: 100%;
    margin-left: -5px;
  }
  .form-group.row > div {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.report-pdf-viewer-cont {
  text-align: center;
  width: 100%;
  height: 1101px;

  button {
    color: #29323a;
    background-color: #22d2e1;
    border: 1px solid #1dcbda;
    border-radius: 0.3rem;
    line-height: 1.5;
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    vertical-align: middle;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.0rem;
    padding-right: 1.0rem;
    cursor: pointer;
    text-transform: capitalize;
    margin-top: 0.5rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    &:hover {
      background-color: white !important;
    }

    &:disabled {
      pointer-events: none !important;
      opacity: 0.25 !important;
      cursor: not-allowed !important;
      &:hover {
        background-color: #22d2e1  !important;
      }
    }

  }

  .rpdfv-navbarWrapper {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .rpdfv-zoomOutBtn {
    i { font-size: 0px; }
    i:after { font-style: normal; font-size: 1.0rem; content: 'Zoom Out'; }
  }
  .rpdfv-zoomResetBtn {
    i { font-size: 0px; }
    i:after { font-style: normal; font-size: 1.0rem; content: 'Reset Zoom'; }
  }
  .rpdfv-zoomInBtn {
    i { font-size: 0px; }
    i:after { font-style: normal; font-size: 1.0rem; content: 'Zoom In'; }
  }
  .rpdfv-pageIndicator {
    display: none;
  }
  .rpdfv-nextPageBtn {
    i { font-size: 0px; }
    i:after { font-style: normal; font-size: 1.0rem; content: 'Next Page'; }
  }
  .rpdfv-previousPageBtn {
    i { font-size: 0px; }
    i:after { font-style: normal; font-size: 1.0rem; content: 'Previous Page'; }
  }
}

.raa-tab-error-dot {
  width: 8px;
  height: 8px;
  background-color: #E67478;
  border-radius: 4px;
  position: absolute;
  right: -4px;
  top: -4px;
}

.raa-error-message {
  font-family: 'Muli';
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.04em;

  color: #E67478;
  position: relative;
  width: 250px;
  right: 5rem;
  top: 8px;
  float: right;
}

.raa-awaiting-message-cont {
  position: relative;
  width: 500px;
  height: 200px;
  left: 50%;
  margin-left: -250px;
  top: 50%;
  margin-top: 125px;
  text-align: center;
  .raa-awaiting-message {
    margin-top: 10px;
    font-family: 'Muli';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 19px;
    color: #FFFFFF;
  }
  .raa-awaiting-message-sub {
    margin-top: 10px;
    font-family: 'Muli';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    color: #B3C9D9;   
  }
}

.raa-awaiting-message-cont-sask {
  position: relative;
  width: 880px;
  height: 200px;
  left: 50%;
  margin-left: -440px;
  top: 50%;
  margin-top: 125px;
  text-align: center;
  .raa-awaiting-message {
    margin-top: 10px;
    font-family: 'Muli';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 19px;
    color: #FFFFFF;
  }
  .raa-awaiting-message-sub {
    margin-top: 10px;
    font-family: 'Muli';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    color: #B3C9D9;   
  }
}

.raa-report-preview-cont {
  background-color: white;
  width: 450px;
  height: 800px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: inline-block;
  border-radius: 4px;
}

.unresponsive-cont {
  display: inline-block;
  margin-right: 64px;
  .simp-toggle {
    top: 6px;
    margin-left: 8px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}