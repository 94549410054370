.ea-invite-advisor-page {
    h1 {
        font-weight: 800;
        font-size: 1.5rem;
        color: #FFF;
    }
    label {
        font-size: 1rem;
        color: #DDD;
        display: block;
    }
    button {
        background-color: #448;
        color: #FFF;
        padding: 12px;
        border-radius: 6px;
    }
    hr {
        background-color: white;
    }
    table {
        margin-top: 32px;
        tr {
            min-width: 500px;
        }
        td, th {
            padding: 8px;
            position: relative;
        }
    }
    .user-edit-popup {
        background-color: #112;
        border-radius: 6px;
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.43);
        position: absolute;
        padding: 1rem;
        top: -300px;
        right: 0px;
        width: 300px;
        &.popup-hidden {
            display: none;
        }
        z-index: 100;
    }
}