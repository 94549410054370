.customer-details-page-container {
  width: 100%;
  display: block;
  margin-top: 0px;
  .page-title-white {
    color: #FFF;
  }
  .customer-details-container {
  	display: inline-block;
  	vertical-align: top;
  	margin-right: 32px;
  }
  .back-button {
  	font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.05em;
		color: #FFFFFF;
		cursor: pointer;
		margin-bottom: 20px;
		img {
			margin-right: 6px;
		}
  }
	.enbuildings-container {
		margin-left: 0px;
		vertical-align: top;
	  display: inline-block !important;
	  width: calc(100% - 375px) !important;
	  .page-title-white {
	  	font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 23px;
			letter-spacing: 0.0015em;
			color: #FFFFFF;
			margin-bottom: 22px;
	  }
	}
}
