.simp-hl-cont {
  position: relative;
  padding-bottom: 32px;
  text-align: center;

  .shl-canvas-cont {
    display: inline-block;
    position: relative;
    margin-bottom: 25px;
    margin-top: 25px;

    .shl-label-walls {
        position: absolute;
        bottom: -25px;
        right: -25px;
        text-align: center;
    }

    .shl-label-ceiling {
        position: absolute;
        bottom: -25px;
        left: -25px;
        text-align: center;
    }

    .shl-label-foundation {
        position: absolute;
        top: -25px;
        left: -25px;
        text-align: center;
    }

    .shl-label-windows {
        position: absolute;
        top: -25px;
        right: -50px;
        text-align: center;
    }

    .shl-label-top {
        display: inline-block;
        color: #D4D4D5;
        font-size: 1.0em;
    }

    .shl-label-bot {
        display: inline-block;
        color: #A4A4A5;
        font-size: 0.9em;
    }
  }
}